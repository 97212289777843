import React, { useState, useEffect, useRef } from 'react';
import './BulletinNeige.css';
import Header from './Header';
import { db, storage } from './firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const BulletinNeige: React.FC = () => {
  // États pour le formulaire
  const [title, setTitle] = useState<string>('');
  const [bodyText, setBodyText] = useState<string>('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [existingImageUrl, setExistingImageUrl] = useState<string>('');
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  // Nouvel état pour isActive
  const [isActive, setIsActive] = useState<boolean>(false);

  // États pour les profondeurs de neige (passés de number à string)
  const [snowDepth1050, setSnowDepth1050] = useState<string>('');
  const [snowDepth1450, setSnowDepth1450] = useState<string>('');
  const [snowDepth1700, setSnowDepth1700] = useState<string>('');
  const [snowDepth2500, setSnowDepth2500] = useState<string>('');

  // Référence pour l'éditeur ReactQuill
  const quillRef = useRef<ReactQuill>(null);

  // Configuration de la barre d'outils de React Quill
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': [1, 2, 3, false] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link', 'image'
  ];

  // Fonction pour ajuster la hauteur de ReactQuill
  const adjustQuillHeight = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor().root;
      editor.style.height = 'auto';
      editor.style.height = `${editor.scrollHeight}px`;
    }
  };

  // Récupération des données existantes depuis Firestore
  useEffect(() => {
    const fetchSnowReport = async () => {
      try {
        const docRef = doc(db, 'forecasts', 'snowReport');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setTitle(data.title || '');
          setBodyText(data.bodyText || '');
          setExistingImageUrl(data.imageUrl || '');
          setIsActive(data.isActive !== undefined ? data.isActive : false); // Récupération de isActive
          setSnowDepth1050(data.snowDepth1050 !== undefined ? data.snowDepth1050.toString() : '');
          setSnowDepth1450(data.snowDepth1450 !== undefined ? data.snowDepth1450.toString() : '');
          setSnowDepth1700(data.snowDepth1700 !== undefined ? data.snowDepth1700.toString() : '');
          setSnowDepth2500(data.snowDepth2500 !== undefined ? data.snowDepth2500.toString() : '');
        }
      } catch (error: any) {
        console.error('Erreur lors de la récupération du Bulletin des Neiges :', error);
        alert(`Une erreur est survenue lors de la récupération des données : ${error.message || 'Erreur inconnue.'}`);
      }
    };

    fetchSnowReport();
  }, []);

  // Ajuster la hauteur de l'éditeur lorsque le contenu change
  useEffect(() => {
    adjustQuillHeight();
  }, [bodyText]);

  // Fonction pour uploader l'image et retourner son URL
  const handleImageUpload = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!validImageTypes.includes(file.type)) {
        reject('Type de fichier non supporté. Veuillez sélectionner une image JPEG, PNG ou GIF.');
        return;
      }

      const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
      if (file.size > maxSizeInBytes) {
        reject('Le fichier est trop volumineux. Veuillez sélectionner une image de moins de 5MB.');
        return;
      }

      const imageRef = ref(storage, `snowReports/${uuidv4()}`);
      const uploadTask = uploadBytesResumable(imageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(Math.round(progress));
        },
        (error) => {
          console.error('Erreur lors de l\'upload de l\'image :', error);
          setUploadError(error.message || 'Erreur inconnue lors de l\'upload de l\'image.');
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error: any) {
            console.error('Erreur lors de la récupération de l\'URL de téléchargement :', error);
            setUploadError(error.message || 'Erreur lors de la récupération de l\'URL de téléchargement.');
            reject(error);
          }
        }
      );
    });
  };

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setUploadError(null);
    setUploadProgress(null);
    setSubmitting(true);

    // Validation des champs de profondeur de neige (si remplis)
    if (
      (snowDepth1050 && Number(snowDepth1050) < 0) ||
      (snowDepth1450 && Number(snowDepth1450) < 0) ||
      (snowDepth1700 && Number(snowDepth1700) < 0) ||
      (snowDepth2500 && Number(snowDepth2500) < 0)
    ) {
      alert('Les profondeurs de neige ne peuvent pas être négatives.');
      setSubmitting(false);
      return;
    }

    try {
      let imageUrl = existingImageUrl; // Utiliser l'image existante par défaut

      if (imageFile) {
        imageUrl = await handleImageUpload(imageFile);
      }

      const snowReportData: any = {
        title,
        bodyText,
        imageUrl,
        isActive, // Inclusion de isActive
        updateAt: new Date(),
      };

      // Ajout des profondeurs de neige si elles sont remplies
      if (snowDepth1050 !== '') snowReportData.snowDepth1050 = Number(snowDepth1050);
      if (snowDepth1450 !== '') snowReportData.snowDepth1450 = Number(snowDepth1450);
      if (snowDepth1700 !== '') snowReportData.snowDepth1700 = Number(snowDepth1700);
      if (snowDepth2500 !== '') snowReportData.snowDepth2500 = Number(snowDepth2500);

      await setDoc(doc(db, 'forecasts', 'snowReport'), snowReportData);

      alert('Le Bulletin des Neiges a été publié avec succès.');

      // Mettre à jour l'image existante si une nouvelle image a été uploadée
      if (imageFile && imageUrl) {
        setExistingImageUrl(imageUrl);
        setImageFile(null); // Réinitialiser le fichier sélectionné
      }
    } catch (error: any) {
      console.error('Erreur lors de la publication du Bulletin des Neiges :', error);
      alert(`Une erreur est survenue : ${error.message || 'Erreur inconnue.'}`);
    } finally {
      setSubmitting(false);
      setUploadProgress(null);
    }
  };

  return (
    <div className="bulletin-neige-page">
      <Header />

      <div className="bulletin-neige-container">
        <h2>Bulletin des Neiges</h2>
        <form onSubmit={handleSubmit} className="bulletin-neige-form">
          {/* Champ Switch isActive */}
          <div className="bulletin-neige-form-group">
            <label htmlFor="isActive" className="bulletin-neige-label">
              Actif :
            </label>
            <label className="switch">
              <input
                id="isActive"
                type="checkbox"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
              />
              <span className="slider round"></span>
            </label>
          </div>

          {/* Champ Titre */}
          <div className="bulletin-neige-form-group">
            <label htmlFor="snowTitle">Titre</label>
            <input
              id="snowTitle"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="bulletin-neige-input"
              placeholder="Titre du bulletin des neiges"
            />
          </div>

          {/* Champ Corps du Texte */}
          <div className="bulletin-neige-form-group">
            <label htmlFor="snowBody">Corps du Texte</label>
            <ReactQuill
              ref={quillRef}
              id="snowBody"
              value={bodyText}
              onChange={setBodyText}
              theme="snow"
              modules={modules}
              formats={formats}
              placeholder="Corps du bulletin des neiges"
              className="bulletin-neige-editor"
            />
          </div>

          {/* Champs pour les Profondeurs de Neige */}
          <div className="bulletin-neige-snow-depth-row">
            <div className="bulletin-neige-form-group bulletin-neige-snow-depth-field">
              <label htmlFor="snowDepth1050">1050m</label>
              <input
                id="snowDepth1050"
                type="number"
                value={snowDepth1050}
                onChange={(e) => setSnowDepth1050(e.target.value)}
                min="0"
                step="0.1"
                className="bulletin-neige-input"
                placeholder="cm"
              />
            </div>

            <div className="bulletin-neige-form-group bulletin-neige-snow-depth-field">
              <label htmlFor="snowDepth1450">1450m</label>
              <input
                id="snowDepth1450"
                type="number"
                value={snowDepth1450}
                onChange={(e) => setSnowDepth1450(e.target.value)}
                min="0"
                step="0.1"
                className="bulletin-neige-input"
                placeholder="cm"
              />
            </div>

            <div className="bulletin-neige-form-group bulletin-neige-snow-depth-field">
              <label htmlFor="snowDepth1700">1700m</label>
              <input
                id="snowDepth1700"
                type="number"
                value={snowDepth1700}
                onChange={(e) => setSnowDepth1700(e.target.value)}
                min="0"
                step="0.1"
                className="bulletin-neige-input"
                placeholder="cm"
              />
            </div>

            <div className="bulletin-neige-form-group bulletin-neige-snow-depth-field">
              <label htmlFor="snowDepth2500">2500m</label>
              <input
                id="snowDepth2500"
                type="number"
                value={snowDepth2500}
                onChange={(e) => setSnowDepth2500(e.target.value)}
                min="0"
                step="0.1"
                className="bulletin-neige-input"
                placeholder="cm"
              />
            </div>
          </div>

          {/* Affichage de l'image existante */}
          {existingImageUrl && !imageFile && (
            <div className="bulletin-neige-existing-image">
              <label>Image actuelle :</label>
              <img src={existingImageUrl} alt="Bulletin actuel" className="bulletin-neige-image" />
            </div>
          )}

          {/* Champ Upload d'Image */}
          <div className="bulletin-neige-form-group">
            <label htmlFor="snowImage">Image associée :</label>
            <input
              id="snowImage"
              type="file"
              accept="image/*"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setImageFile(e.target.files[0]);
                }
              }}
              className="bulletin-neige-file-input"
            />
            {/* Affichage de l'aperçu de la nouvelle image sélectionnée */}
            {imageFile && (
              <div className="bulletin-neige-image-preview">
                <label>Nouvelle image sélectionnée :</label>
                <img src={URL.createObjectURL(imageFile)} alt="Nouvelle sélection" className="bulletin-neige-image" />
              </div>
            )}
          </div>

          {/* Indicateur de Progression de l'Upload */}
          {uploadProgress !== null && (
            <div className="bulletin-neige-upload-progress">
              <p>Upload en cours : {uploadProgress}%</p>
              <progress value={uploadProgress} max="100"></progress>
            </div>
          )}

          {/* Affichage des Erreurs d'Upload */}
          {uploadError && (
            <div className="bulletin-neige-upload-error">
              <p>{uploadError}</p>
            </div>
          )}

          {/* Bouton de Soumission */}
          <button
            type="submit"
            className="bulletin-neige-submit-button"
            disabled={submitting || (uploadProgress !== null && uploadProgress < 100)}
          >
            {submitting || (uploadProgress !== null && uploadProgress < 100)
              ? 'Téléchargement en cours...'
              : 'Publier le Bulletin des Neiges'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default BulletinNeige;