const weatherIcons: { [key: number]: string } = {
    0: 'clear.svg',
    1: 'cloudy_2.svg',
    2: 'cloudy_3.svg',
    3: 'cloudy_6.svg',
    45: 'cirrus_4.svg',
    48: 'cirrus_4.svg',
    51: 'rain_1.svg',
    53: 'rain_2.svg',
    55: 'rain_2.svg',
    56: 'ice_rain_1.svg',
    57: 'ice_rain_2.svg',
    61: 'rain_2.svg',
    63: 'rain_3.svg',
    65: 'rain_4.svg',
    66: 'ice_rain_2.svg',
    67: 'ice_rain_3.svg',
    71: 'snow_1.svg',
    73: 'snow_3.svg',
    75: 'snow_4.svg',
    77: 'snow_3.svg',
    80: 'rain_shower_2.svg',
    81: 'rain_shower_3.svg',
    82: 'rain_shower_4.svg',
    85: 'snow_shower_2.svg',
    86: 'snow_shower_3.svg',
    95: 'storm_shower_2.svg',
    96: 'storm_shower_3.svg',
    99: 'storm_shower_4.svg',
  };
  
  export const getWeatherIcon = (weatherCode: number | undefined, isNight: boolean): string => {
    // Vérifie que le weatherCode est défini
    if (weatherCode === undefined || weatherIcons[weatherCode] === undefined) {
      // Retourne l'icône par défaut si le weatherCode n'est pas trouvé ou est indéfini
      return `${process.env.PUBLIC_URL}/assets/icons/${isNight ? 'night' : 'day'}/clear.svg`;
    }
  
    const icon = weatherIcons[weatherCode];
  
    // Utilise les icônes de jour ou de nuit en fonction de l'heure
    const iconPath = isNight
      ? `${process.env.PUBLIC_URL}/assets/icons/night/${icon}`
      : `${process.env.PUBLIC_URL}/assets/icons/day/${icon}`;
  
    return iconPath;
  };