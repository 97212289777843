import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { getWeatherIcon } from './utils/getWeatherIcon';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase';
import Header from './Header';

import './Previsions.css';

// Icônes jour
const ClearIcon = `${process.env.PUBLIC_URL}/assets/icons/day/clear.svg`;
const Cloudy1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_1.svg`;
const Cloudy2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_2.svg`;
const Cloudy3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_3.svg`;
const Cloudy4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_4.svg`;
const Cloudy5Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_5.svg`;
const Cloudy6Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cloudy_6.svg`;
const Cirrus1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_1.svg`;
const Cirrus2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_2.svg`;
const Cirrus3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_3.svg`;
const Cirrus4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/cirrus_4.svg`;
const Rain1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_1.svg`;
const Rain2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_2.svg`;
const Rain3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_3.svg`;
const Rain4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_4.svg`;
const RainShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_1.svg`;
const RainShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_2.svg`;
const RainShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_3.svg`;
const RainShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_shower_4.svg`;
const RainSnow1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_1.svg`;
const RainSnow2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_2.svg`;
const RainSnow3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_3.svg`;
const RainSnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_1.svg`;
const RainSnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_2.svg`;
const RainSnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_3.svg`;
const RainSnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/rain_snow_shower_4.svg`;
const IceRain1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_1.svg`;
const IceRain2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_2.svg`;
const IceRain3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_3.svg`;
const IceRain4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/ice_rain_4.svg`;
const Snow1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_1.svg`;
const Snow2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_2.svg`;
const Snow3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_3.svg`;
const Snow4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_4.svg`;
const SnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_1.svg`;
const SnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_2.svg`;
const SnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_3.svg`;
const SnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/snow_shower_4.svg`;
const Storm1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_1.svg`;
const Storm2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_2.svg`;
const Storm3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_3.svg`;
const StormShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_1.svg`;
const StormShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_2.svg`;
const StormShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_3.svg`;
const StormShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/day/storm_shower_4.svg`;

// Icônes nuit
const NightClearIcon = `${process.env.PUBLIC_URL}/assets/icons/night/clear.svg`;
const NightCloudy1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_1.svg`;
const NightCloudy2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_2.svg`;
const NightCloudy3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_3.svg`;
const NightCloudy4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_4.svg`;
const NightCloudy5Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_5.svg`;
const NightCloudy6Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cloudy_6.svg`;
const NightCirrus1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_1.svg`;
const NightCirrus2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_2.svg`;
const NightCirrus3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_3.svg`;
const NightCirrus4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/cirrus_4.svg`;
const NightRain1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_1.svg`;
const NightRain2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_2.svg`;
const NightRain3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_3.svg`;
const NightRain4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_4.svg`;
const NightRainShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_1.svg`;
const NightRainShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_2.svg`;
const NightRainShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_3.svg`;
const NightRainShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_shower_4.svg`;
const NightRainSnow1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_1.svg`;
const NightRainSnow2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_2.svg`;
const NightRainSnow3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_3.svg`;
const NightRainSnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_1.svg`;
const NightRainSnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_2.svg`;
const NightRainSnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_3.svg`;
const NightRainSnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/rain_snow_shower_4.svg`;
const NightIceRain1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_1.svg`;
const NightIceRain2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_2.svg`;
const NightIceRain3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_3.svg`;
const NightIceRain4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/ice_rain_4.svg`;
const NightSnow1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_1.svg`;
const NightSnow2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_2.svg`;
const NightSnow3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_3.svg`;
const NightSnow4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_4.svg`;
const NightSnowShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_1.svg`;
const NightSnowShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_2.svg`;
const NightSnowShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_3.svg`;
const NightSnowShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/snow_shower_4.svg`;
const NightStorm1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_1.svg`;
const NightStorm2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_2.svg`;
const NightStorm3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_3.svg`;
const NightStormShower1Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_1.svg`;
const NightStormShower2Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_2.svg`;
const NightStormShower3Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_3.svg`;
const NightStormShower4Icon = `${process.env.PUBLIC_URL}/assets/icons/night/storm_shower_4.svg`;

// Tableaux séparés pour les icônes jour et nuit
const dayIcons = [
  ClearIcon,
  Cloudy1Icon,
  Cloudy2Icon,
  Cloudy3Icon,
  Cloudy4Icon,
  Cloudy5Icon,
  Cloudy6Icon,
  Cirrus1Icon,
  Cirrus2Icon,
  Cirrus3Icon,
  Cirrus4Icon,
  Rain1Icon,
  Rain2Icon,
  Rain3Icon,
  Rain4Icon,
  RainShower1Icon,
  RainShower2Icon,
  RainShower3Icon,
  RainShower4Icon,
  RainSnow1Icon,
  RainSnow2Icon,
  RainSnow3Icon,
  RainSnowShower1Icon,
  RainSnowShower2Icon,
  RainSnowShower3Icon,
  RainSnowShower4Icon,
  IceRain1Icon,
  IceRain2Icon,
  IceRain3Icon,
  IceRain4Icon,
  Snow1Icon,
  Snow2Icon,
  Snow3Icon,
  Snow4Icon,
  SnowShower1Icon,
  SnowShower2Icon,
  SnowShower3Icon,
  SnowShower4Icon,
  Storm1Icon,
  Storm2Icon,
  Storm3Icon,
  StormShower1Icon,
  StormShower2Icon,
  StormShower3Icon,
  StormShower4Icon,
];

const nightIcons = [
  NightClearIcon,
  NightCloudy1Icon,
  NightCloudy2Icon,
  NightCloudy3Icon,
  NightCloudy4Icon,
  NightCloudy5Icon,
  NightCloudy6Icon,
  NightCirrus1Icon,
  NightCirrus2Icon,
  NightCirrus3Icon,
  NightCirrus4Icon,
  NightRain1Icon,
  NightRain2Icon,
  NightRain3Icon,
  NightRain4Icon,
  NightRainShower1Icon,
  NightRainShower2Icon,
  NightRainShower3Icon,
  NightRainShower4Icon,
  NightRainSnow1Icon,
  NightRainSnow2Icon,
  NightRainSnow3Icon,
  NightRainSnowShower1Icon,
  NightRainSnowShower2Icon,
  NightRainSnowShower3Icon,
  NightRainSnowShower4Icon,
  NightIceRain1Icon,
  NightIceRain2Icon,
  NightIceRain3Icon,
  NightIceRain4Icon,
  NightSnow1Icon,
  NightSnow2Icon,
  NightSnow3Icon,
  NightSnow4Icon,
  NightSnowShower1Icon,
  NightSnowShower2Icon,
  NightSnowShower3Icon,
  NightSnowShower4Icon,
  NightStorm1Icon,
  NightStorm2Icon,
  NightStorm3Icon,
  NightStormShower1Icon,
  NightStormShower2Icon,
  NightStormShower3Icon,
  NightStormShower4Icon,
];

// Fonction pour arrondir à l'entier le plus proche
const roundToNearestInteger = (value: number): number => {
  return Math.round(value);
};

// Fonction pour formater la neige (affichage entier)
const formatSnowfall = (snowfall: number): string => {
  if (snowfall < 0.25) {
    return '0 cm';
  }
  const rounded = Math.round(snowfall);
  return `${rounded} cm`;
};

// Fonction pour arrondir au 100m près (inchangée)
const roundToNearest100 = (value: number): number => {
  return Math.round(value / 100) * 100;
};

// Définir un type pour les périodes valides
type ForecastPeriod = 'morningData' | 'afternoonData' | 'eveningData' | 'nightData';
type ReliabilityType = 'bonne' | 'moyenne' | 'mauvaise';

// Réorganisé selon l'ordre souhaité
interface PeriodData {
  temperature1050?: number; // T1050
  temperature1450?: number; // T1450
  temperature1700?: number; // T1700
  temperature2500?: number; // T2500
  windGust1050?: number; // FF1050
  windGust1450?: number; // FF1450
  windGust1700?: number; // FF1700
  windGust2500?: number; // FF2500
  icon: string;
  snowfall1050?: number;
  snowfall1450?: number;
  snowfall1700?: number;
  snowfall2500?: number;
}

interface ForecastData {
  morningData?: PeriodData;
  afternoonData?: PeriodData;
  eveningData?: PeriodData;
  nightData?: PeriodData;
  MountainBulletin?: string;
  alertOrange?: boolean;
  alertRed?: boolean;
  reliability?: ReliabilityType;
  sunrise?: string;
  sunset?: string;
  iso0morning?: string;
  iso0afternoon?: string;
  iso0evening?: string;
  iso0night?: string;
  modeAuto?: boolean; // Ajouté pour gérer le mode auto par jour
}

// Fonction pour obtenir la valeur la plus fréquente (mode) (inchangée)
const mode = (arr: any[]) => {
  const counts: { [key: string]: number } = {};
  let maxCount = 0;
  let modeValue = arr[0];

  arr.forEach((val) => {
    counts[val] = (counts[val] || 0) + 1;
    if (counts[val] > maxCount) {
      maxCount = counts[val];
      modeValue = val;
    }
  });
  return modeValue;
};

// Déterminer si le coucher du soleil est avant 20h00 (inchangé)
const isSunsetBefore20 = (sunset: string): boolean => {
  const sunsetDate = new Date(sunset);
  const sunsetHours = sunsetDate.getHours();
  const sunsetMinutes = sunsetDate.getMinutes();
  return sunsetHours < 20 || (sunsetHours === 20 && sunsetMinutes === 0);
};

// Fonction pour obtenir les données d'une période (inchangée sauf l'arrondi des accumulations)
const getPeriodData = (
  data: any[],
  startHour: number,
  endHour: number,
  type: 'morning' | 'afternoon' | 'avg',
  isNight: boolean
): PeriodData => {
  const filtered = data.filter((hourly: any) => {
    const hour = new Date(hourly.forecastStart).getHours();
    return hour >= startHour && hour <= endHour;
  });

  // Si aucune donnée, on retourne un objet "vide" avec icon par défaut
  if (filtered.length === 0) {
    return {
      icon: getWeatherIcon(0, isNight),
      snowfall1050: 0,
      snowfall1450: 0,
      snowfall1700: 0,
      snowfall2500: 0,
    };
  }

  const temperatures1050 = filtered.map((h: any) => h.temperature1050);
  const temperatures1450 = filtered.map((h: any) => h.temperature1450);
  const temperatures1700 = filtered.map((h: any) => h.temperature1700);
  const temperatures2500 = filtered.map((h: any) => h.temperature2500);

  const windGusts1050 = filtered.map((h: any) => h.windGust1050);
  const windGusts1450 = filtered.map((h: any) => h.windGust1450);
  const windGusts1700 = filtered.map((h: any) => h.windGust1700);
  const windGusts2500 = filtered.map((h: any) => h.windGust2500);

  const weatherCodes = filtered.map((h: any) => h.weatherCode);

  const snowfalls1050 = filtered.map((h: any) => h.snowfall1050 || 0);
  const snowfalls1450 = filtered.map((h: any) => h.snowfall1450 || 0);
  const snowfalls1700 = filtered.map((h: any) => h.snowfall1700 || 0);
  const snowfalls2500 = filtered.map((h: any) => h.snowfall2500 || 0);

  let temperature1050: number | undefined;
  let temperature1450: number | undefined;
  let temperature1700: number | undefined;
  let temperature2500: number | undefined;
  let snowfall1050 = 0;
  let snowfall1450 = 0;
  let snowfall1700 = 0;
  let snowfall2500 = 0;

  if (type === 'morning') {
    const morningHour = filtered.find((h) => new Date(h.forecastStart).getHours() === 9);
    temperature1050 = morningHour?.temperature1050;
    temperature1450 = morningHour?.temperature1450;
    temperature1700 = morningHour?.temperature1700;
    temperature2500 = morningHour?.temperature2500;
    snowfall1050 = morningHour?.snowfall1050 || 0;
    snowfall1450 = morningHour?.snowfall1450 || 0;
    snowfall1700 = morningHour?.snowfall1700 || 0;
    snowfall2500 = morningHour?.snowfall2500 || 0;
  } else if (type === 'afternoon') {
    const afternoonHour = filtered.find((h) => new Date(h.forecastStart).getHours() === 15);
    temperature1050 = afternoonHour?.temperature1050;
    temperature1450 = afternoonHour?.temperature1450;
    temperature1700 = afternoonHour?.temperature1700;
    temperature2500 = afternoonHour?.temperature2500;
    snowfall1050 = afternoonHour?.snowfall1050 || 0;
    snowfall1450 = afternoonHour?.snowfall1450 || 0;
    snowfall1700 = afternoonHour?.snowfall1700 || 0;
    snowfall2500 = afternoonHour?.snowfall2500 || 0;
  } else {
    // calcul d'une moyenne
    temperature1050 =
      temperatures1050.reduce((sum: number, val: number) => sum + val, 0) /
      temperatures1050.length;
    temperature1450 =
      temperatures1450.reduce((sum: number, val: number) => sum + val, 0) /
      temperatures1450.length;
    temperature1700 =
      temperatures1700.reduce((sum: number, val: number) => sum + val, 0) /
      temperatures1700.length;
    temperature2500 =
      temperatures2500.reduce((sum: number, val: number) => sum + val, 0) /
      temperatures2500.length;

    snowfall1050 = snowfalls1050.reduce((sum: number, s: number) => sum + s, 0);
    snowfall1450 = snowfalls1450.reduce((sum: number, s: number) => sum + s, 0);
    snowfall1700 = snowfalls1700.reduce((sum: number, s: number) => sum + s, 0);
    snowfall2500 = snowfalls2500.reduce((sum: number, s: number) => sum + s, 0);
  }

  const maxWindGust1050 = Math.max(...windGusts1050);
  const maxWindGust1450 = Math.max(...windGusts1450);
  const maxWindGust1700 = Math.max(...windGusts1700);
  const maxWindGust2500 = Math.max(...windGusts2500);

  const weatherCode = mode(weatherCodes);
  const icon = getWeatherIcon(weatherCode, isNight);

  return {
    temperature1050: temperature1050 !== undefined ? Math.round(temperature1050) : undefined,
    temperature1450: temperature1450 !== undefined ? Math.round(temperature1450) : undefined,
    temperature1700: temperature1700 !== undefined ? Math.round(temperature1700) : undefined,
    temperature2500: temperature2500 !== undefined ? Math.round(temperature2500) : undefined,
    windGust1050: Math.round(maxWindGust1050 / 5) * 5,
    windGust1450: Math.round(maxWindGust1450 / 5) * 5,
    windGust1700: Math.round(maxWindGust1700 / 5) * 5,
    windGust2500: Math.round(maxWindGust2500 / 5) * 5,
    icon,
    snowfall1050,
    snowfall1450,
    snowfall1700,
    snowfall2500,
  };
};

// Fonction pour supprimer les champs undefined d'un objet
const removeUndefined = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeUndefined);
  } else if (obj && typeof obj === 'object') {
    const newObj: any = {};
    Object.keys(obj).forEach((key) => {
      const value = removeUndefined(obj[key]);
      if (value !== undefined) {
        newObj[key] = value;
      }
    });
    return newObj;
  } else {
    return obj;
  }
};

// Nouveau composant pour afficher et ÉDITER le cumul de neige sur 7 jours
interface SnowAccumulationProps {
  apiSnow1050Total: number;
  apiSnow1450Total: number;
  apiSnow1700Total: number;
  apiSnow2500Total: number;
  snowAccumulation7d1050: number | undefined;
  setSnowAccumulation7d1050: (value: number | undefined) => void;
  snowAccumulation7d1450: number | undefined;
  setSnowAccumulation7d1450: (value: number | undefined) => void;
  snowAccumulation7d1700: number | undefined;
  setSnowAccumulation7d1700: (value: number | undefined) => void;
  snowAccumulation7d2500: number | undefined;
  setSnowAccumulation7d2500: (value: number | undefined) => void;
  excludeSnowAccumulationFromAuto: boolean; // Sauvegardé dans Firestore
  setExcludeSnowAccumulationFromAuto: (value: boolean) => void; // Sauvegardé dans Firestore
}

const SnowAccumulation: React.FC<SnowAccumulationProps> = ({
  apiSnow1050Total,
  apiSnow1450Total,
  apiSnow1700Total,
  apiSnow2500Total,
  snowAccumulation7d1050,
  setSnowAccumulation7d1050,
  snowAccumulation7d1450,
  setSnowAccumulation7d1450,
  snowAccumulation7d1700,
  setSnowAccumulation7d1700,
  snowAccumulation7d2500,
  setSnowAccumulation7d2500,
  excludeSnowAccumulationFromAuto,
  setExcludeSnowAccumulationFromAuto,
}) => {
  // Fonction reset pour remettre la valeur API dans l'input
  const handleReset = (altitude: '1050' | '1450' | '1700' | '2500') => {
    switch (altitude) {
      case '1050':
        setSnowAccumulation7d1050(apiSnow1050Total);
        break;
      case '1450':
        setSnowAccumulation7d1450(apiSnow1450Total);
        break;
      case '1700':
        setSnowAccumulation7d1700(apiSnow1700Total);
        break;
      case '2500':
        setSnowAccumulation7d2500(apiSnow2500Total);
        break;
      default:
        break;
    }
  };

  return (
    <div className="snowfall-summary">
      <h2>Cumul de neige sur 7 jours</h2>

      {/* SN1050 */}
      <div className="snowfall-field">
        <label htmlFor="7dSnowAccumulation1050">7dSnowAccumulation1050</label>
        <input
          id="7dSnowAccumulation1050"
          type="number"
          step="1"
          value={snowAccumulation7d1050 !== undefined ? snowAccumulation7d1050 : ''}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue === '') {
              setSnowAccumulation7d1050(undefined);
            } else {
              const parsed = parseFloat(inputValue);
              if (!isNaN(parsed)) {
                setSnowAccumulation7d1050(roundToNearestInteger(parsed));
              }
            }
          }}
          className="input-standard"
        />
        <span className="api-value">(API: {formatSnowfall(apiSnow1050Total)})</span>
        <button onClick={() => handleReset('1050')} className="reset-button">
          Reset
        </button>
      </div>

      {/* SN1450 */}
      <div className="snowfall-field">
        <label htmlFor="7dSnowAccumulation1450">7dSnowAccumulation1450</label>
        <input
          id="7dSnowAccumulation1450"
          type="number"
          step="1"
          value={snowAccumulation7d1450 !== undefined ? snowAccumulation7d1450 : ''}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue === '') {
              setSnowAccumulation7d1450(undefined);
            } else {
              const parsed = parseFloat(inputValue);
              if (!isNaN(parsed)) {
                setSnowAccumulation7d1450(roundToNearestInteger(parsed));
              }
            }
          }}
          className="input-standard"
        />
        <span className="api-value">(API: {formatSnowfall(apiSnow1450Total)})</span>
        <button onClick={() => handleReset('1450')} className="reset-button">
          Reset
        </button>
      </div>

      {/* SN1700 */}
      <div className="snowfall-field">
        <label htmlFor="7dSnowAccumulation1700">7dSnowAccumulation1700</label>
        <input
          id="7dSnowAccumulation1700"
          type="number"
          step="1"
          value={snowAccumulation7d1700 !== undefined ? snowAccumulation7d1700 : ''}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue === '') {
              setSnowAccumulation7d1700(undefined);
            } else {
              const parsed = parseFloat(inputValue);
              if (!isNaN(parsed)) {
                setSnowAccumulation7d1700(roundToNearestInteger(parsed));
              }
            }
          }}
          className="input-standard"
        />
        <span className="api-value">(API: {formatSnowfall(apiSnow1700Total)})</span>
        <button onClick={() => handleReset('1700')} className="reset-button">
          Reset
        </button>
      </div>

      {/* SN2500 */}
      <div className="snowfall-field">
        <label htmlFor="7dSnowAccumulation2500">7dSnowAccumulation2500</label>
        <input
          id="7dSnowAccumulation2500"
          type="number"
          step="1"
          value={snowAccumulation7d2500 !== undefined ? snowAccumulation7d2500 : ''}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue === '') {
              setSnowAccumulation7d2500(undefined);
            } else {
              const parsed = parseFloat(inputValue);
              if (!isNaN(parsed)) {
                setSnowAccumulation7d2500(roundToNearestInteger(parsed));
              }
            }
          }}
          className="input-standard"
        />
        <span className="api-value">(API: {formatSnowfall(apiSnow2500Total)})</span>
        <button onClick={() => handleReset('2500')} className="reset-button">
          Reset
        </button>
      </div>

      {/* Switch pour exclure les 7dSnowAccumulationXXXX du mode auto */}
      <div className="snowfall-field">
        <label className="switch">
          <input
            type="checkbox"
            checked={excludeSnowAccumulationFromAuto}
            onChange={(e) => setExcludeSnowAccumulationFromAuto(e.target.checked)}
          />
          <span className="slider round"></span>
        </label>
        <span>Exclure du mode auto les 7dSnowAccumulationXXXX</span>
      </div>
    </div>
  );
};

const PrevisionsMontagne: React.FC = () => {
  const [weatherData, setWeatherData] = useState<any>(null);
  const [expertForecasts, setExpertForecasts] = useState<Record<string, ForecastData>>({});
  const [sidePanelOpen, setSidePanelOpen] = useState<boolean>(false);
  const [editingDay, setEditingDay] = useState<{
    day: string;
    period: ForecastPeriod;
  } | null>(null);

  // Loader et messages
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);

  // Décalage manuel
  const [shiftLoading, setShiftLoading] = useState<boolean>(false);
  const [shiftMessage, setShiftMessage] = useState<string | null>(null);
  const [shiftMessageType, setShiftMessageType] = useState<'success' | 'error' | null>(null);

  // Réfs pour éviter réinitialisations multiples
  const hasVacationResetRef = useRef<boolean>(false);
  const hasJ9J13ResetRef = useRef<boolean>(false);

  const sidePanelRef = useRef<HTMLDivElement>(null);

  // États séparés pour les valeurs de neige éditées
  const [snowAccumulation7d1050, setSnowAccumulation7d1050] = useState<number | undefined>(0);
  const [snowAccumulation7d1450, setSnowAccumulation7d1450] = useState<number | undefined>(0);
  const [snowAccumulation7d1700, setSnowAccumulation7d1700] = useState<number | undefined>(0);
  const [snowAccumulation7d2500, setSnowAccumulation7d2500] = useState<number | undefined>(0);

  // Nouveaux états pour les totaux de neige de l'API
  const [apiTotalSnow1050, setApiTotalSnow1050] = useState<number>(0);
  const [apiTotalSnow1450, setApiTotalSnow1450] = useState<number>(0);
  const [apiTotalSnow1700, setApiTotalSnow1700] = useState<number>(0);
  const [apiTotalSnow2500, setApiTotalSnow2500] = useState<number>(0);

  // Ajout de l'état pour exclure les champs du mode auto
  const [excludeSnowAccumulationFromAuto, setExcludeSnowAccumulationFromAuto] = useState<boolean>(false);

  /**
   * Réinitialisation d'une journée (resetExpertForecastForDay)
   */
  const resetExpertForecastForDay = useCallback(
    (dayNumber: string, reliability?: ReliabilityType) => {
      const dayIndex = parseInt(dayNumber.substring(1), 10);

      if (!weatherData || !weatherData.forecastDaily || !weatherData.forecastDaily.days[dayIndex]) {
        console.error(`Aucune donnée disponible pour le jour ${dayNumber}`);
        setMessage(`Aucune donnée disponible pour le jour ${dayNumber}.`);
        setMessageType('error');
        return;
      }

      const apiDay = weatherData.forecastDaily.days[dayIndex];
      const dayDate = new Date(apiDay.forecastStart);
      dayDate.setHours(0, 0, 0, 0);
      const dayStart = dayDate.getTime();
      const dayEnd = dayStart + 24 * 60 * 60 * 1000 - 1;

      const hoursForDay = weatherData.forecastHourly.hours.filter((hour: any) => {
        const forecastTime = new Date(hour.forecastStart).getTime();
        return forecastTime >= dayStart && forecastTime <= dayEnd;
      });

      if (!hoursForDay || hoursForDay.length === 0) {
        console.error(`Aucune donnée horaire disponible pour le jour ${dayNumber}`);
        setMessage(`Aucune donnée horaire disponible pour le jour ${dayNumber}.`);
        setMessageType('error');
        return;
      }

      const iso0morningRaw = hoursForDay.find((h: any) => new Date(h.forecastStart).getHours() === 9)
        ?.freezing_level_height450;
      const iso0morning =
        iso0morningRaw !== undefined ? roundToNearest100(iso0morningRaw).toString() : undefined;

      const iso0afternoonRaw = hoursForDay.find(
        (h: any) => new Date(h.forecastStart).getHours() === 15
      )?.freezing_level_height450;
      const iso0afternoon =
        iso0afternoonRaw !== undefined ? roundToNearest100(iso0afternoonRaw).toString() : undefined;

      const iso0eveningRaw = hoursForDay.find(
        (h: any) => new Date(h.forecastStart).getHours() === 21
      )?.freezing_level_height450;
      const iso0evening =
        iso0eveningRaw !== undefined ? roundToNearest100(iso0eveningRaw).toString() : undefined;

      // iso0night => 3h du matin du jour suivant
      const nextDayIndex = dayIndex + 1;
      let iso0night: string | undefined;
      if (weatherData.forecastDaily.days[nextDayIndex]) {
        const nextApiDay = weatherData.forecastDaily.days[nextDayIndex];
        const nextDayDate = new Date(nextApiDay.forecastStart);
        nextDayDate.setHours(0, 0, 0, 0);
        const dayStartNext = nextDayDate.getTime();
        const dayEndNext = dayStartNext + 6 * 60 * 60 * 1000 - 1;
        const hoursForNextDay = weatherData.forecastHourly.hours.filter((hour: any) => {
          const forecastTime = new Date(hour.forecastStart).getTime();
          return forecastTime >= dayStartNext && forecastTime <= dayEndNext;
        });
        const iso0nightRaw = hoursForNextDay.find(
          (h: any) => new Date(h.forecastStart).getHours() === 3
        )?.freezing_level_height450;
        iso0night = iso0nightRaw !== undefined ? roundToNearest100(iso0nightRaw).toString() : undefined;
      }

      const isEveningNight = isSunsetBefore20(apiDay.sunset);

      const newExpertForecast: ForecastData = {
        morningData: getPeriodData(hoursForDay, 6, 11, 'morning', false),
        afternoonData: getPeriodData(hoursForDay, 12, 17, 'afternoon', false),
        eveningData: getPeriodData(hoursForDay, 18, 23, 'avg', isEveningNight),
        nightData: getPeriodData(
          weatherData.forecastHourly.hours.filter((hour: any) => {
            const fcTime = new Date(hour.forecastStart).getTime();
            const tomorrowDate = new Date(apiDay.forecastStart);
            tomorrowDate.setDate(tomorrowDate.getDate() + 1);
            tomorrowDate.setHours(0, 0, 0, 0);
            const dayStartNext = tomorrowDate.getTime();
            const dayEndNext = dayStartNext + 6 * 60 * 60 * 1000 - 1;
            return fcTime >= dayStartNext && fcTime <= dayEndNext;
          }),
          0,
          5,
          'avg',
          true
        ),
        sunrise: apiDay.sunrise,
        sunset: apiDay.sunset,
        iso0morning,
        iso0afternoon,
        iso0evening,
        iso0night,
        modeAuto: true, // Mode auto activé lors de la réinitialisation
        ...(reliability && { reliability }),
      };

      setExpertForecasts((prev) => ({
        ...prev,
        [`${dayNumber}-expert`]: {
          ...prev[`${dayNumber}-expert`],
          morningData: newExpertForecast.morningData,
          afternoonData: newExpertForecast.afternoonData,
          eveningData: newExpertForecast.eveningData,
          nightData: newExpertForecast.nightData,
          sunrise: newExpertForecast.sunrise,
          sunset: newExpertForecast.sunset,
          iso0morning: newExpertForecast.iso0morning,
          iso0afternoon: newExpertForecast.iso0afternoon,
          iso0evening: newExpertForecast.iso0evening,
          iso0night: newExpertForecast.iso0night,
          modeAuto: newExpertForecast.modeAuto, // Sauvegarde du mode auto
          ...(reliability && { reliability }),
          alertOrange: false, // Réinitialisation des alertes
          alertRed: false,
        },
      }));
    },
    [weatherData]
  );

  /**
   * Sauvegarde (saveForecasts)
   */
  const saveForecasts = useCallback(
    async (shouldReload: boolean = false) => {
      setLoading(true);
      setMessage(null);
      setMessageType(null);
      try {
        const cleanedForecasts = removeUndefined(expertForecasts);

        // Préparer les données à sauvegarder, incluant:
        // - Les valeurs de neige dans un sous-objet '7dSnowAccumulation'
        // - La valeur du switch 'excludeSnowAccumulationFromAuto'
        const dataToSave: Record<string, any> = {
          ...cleanedForecasts,
          excludeSnowAccumulationFromAuto, // On sauvegarde la valeur du switch
          '7dSnowAccumulation': {
            '7dSnowAccumulation1050':
              snowAccumulation7d1050 !== undefined ? roundToNearestInteger(snowAccumulation7d1050) : undefined,
            '7dSnowAccumulation1450':
              snowAccumulation7d1450 !== undefined ? roundToNearestInteger(snowAccumulation7d1450) : undefined,
            '7dSnowAccumulation1700':
              snowAccumulation7d1700 !== undefined ? roundToNearestInteger(snowAccumulation7d1700) : undefined,
            '7dSnowAccumulation2500':
              snowAccumulation7d2500 !== undefined ? roundToNearestInteger(snowAccumulation7d2500) : undefined,
          },
        };

        // Sauvegarde dans Firestore avec merge
        await setDoc(doc(db, 'forecasts', 'expertForecastsMountainDefault1700'), dataToSave, {
          merge: true,
        });

        setMessage('Prévisions mises à jour avec succès !');
        setMessageType('success');

        if (shouldReload) {
          // Logic de rechargement éventuel
        }
      } catch (error) {
        console.error('Erreur lors de la sauvegarde des prévisions:', error);
        setMessage('Erreur lors de la mise à jour des prévisions.');
        setMessageType('error');
      } finally {
        setLoading(false);
      }
    },
    [
      expertForecasts,
      snowAccumulation7d1050,
      snowAccumulation7d1450,
      snowAccumulation7d1700,
      snowAccumulation7d2500,
      excludeSnowAccumulationFromAuto,
    ]
  );

  // Réinitialiser toutes les prévisions en mode auto
  const resetAllForecastsForVacationMode = useCallback(async () => {
    if (!weatherData) return;
    setLoading(true);
    setMessage(null);
    setMessageType(null);

    try {
      const daysToReset = Array.from({ length: 14 }, (_, i) => `J${i}`);

      for (let i = 0; i < daysToReset.length; i++) {
        const dayNumber = daysToReset[i];
        let reliability: ReliabilityType;
        if (i <= 3) {
          reliability = 'bonne';
        } else if (i <= 5) {
          reliability = 'moyenne';
        } else {
          reliability = 'mauvaise';
        }
        const isModeAuto = expertForecasts[`${dayNumber}-expert`]?.modeAuto === true;
        if (isModeAuto) {
          resetExpertForecastForDay(dayNumber, reliability);
        }
      }

      // Réinitialiser les champs SnowAccumulation avec les totaux de l'API, seulement si non exclu
      if (!excludeSnowAccumulationFromAuto) {
        setSnowAccumulation7d1050(roundToNearestInteger(apiTotalSnow1050));
        setSnowAccumulation7d1450(roundToNearestInteger(apiTotalSnow1450));
        setSnowAccumulation7d1700(roundToNearestInteger(apiTotalSnow1700));
        setSnowAccumulation7d2500(roundToNearestInteger(apiTotalSnow2500));
      }

      const allForecasts: Record<string, any> = {
        ...expertForecasts,
        ...( !excludeSnowAccumulationFromAuto && {
          '7dSnowAccumulation': {
            '7dSnowAccumulation1050': roundToNearestInteger(apiTotalSnow1050),
            '7dSnowAccumulation1450': roundToNearestInteger(apiTotalSnow1450),
            '7dSnowAccumulation1700': roundToNearestInteger(apiTotalSnow1700),
            '7dSnowAccumulation2500': roundToNearestInteger(apiTotalSnow2500),
          },
        }),
        // Les modeAuto sont déjà inclus dans expertForecasts
        excludeSnowAccumulationFromAuto, // On le sauvegarde également
      };

      const cleanedForecasts = removeUndefined(allForecasts);
      await setDoc(doc(db, 'forecasts', 'expertForecastsMountainDefault1700'), cleanedForecasts, {
        merge: true,
      });

      setMessage('Prévisions réinitialisées en mode auto avec succès !');
      setMessageType('success');
      hasVacationResetRef.current = true;
    } catch (error) {
      console.error('Erreur lors de la réinitialisation des prévisions en mode auto:', error);
      setMessage('Erreur lors de la réinitialisation des prévisions en mode auto.');
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  }, [
    weatherData,
    resetExpertForecastForDay,
    expertForecasts,
    apiTotalSnow1050,
    apiTotalSnow1450,
    apiTotalSnow1700,
    apiTotalSnow2500,
    excludeSnowAccumulationFromAuto,
  ]);

  // Fermeture du sidepanel en cliquant en dehors
  const handleClickOutside = (event: MouseEvent) => {
    if (sidePanelRef.current && !sidePanelRef.current.contains(event.target as Node)) {
      setSidePanelOpen(false);
    }
  };

  useEffect(() => {
    if (sidePanelOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidePanelOpen]);

  // Masquer le message principal après 5s
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
        setMessageType(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  // Masquer le message de décalage après 5s
  useEffect(() => {
    if (shiftMessage) {
      const timer = setTimeout(() => {
        setShiftMessage(null);
        setShiftMessageType(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [shiftMessage]);

  // Récupération initiale des données météo (5 APIs)
  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const [response1700, response1050, response1450, response2500, response450] = await Promise.all([
          axios.get(
            /*1700m sans freezing_level_height*/
            'https://api.open-meteo.com/v1/forecast?latitude=45.796034&longitude=6.104622&hourly=temperature_2m,snowfall,weather_code,wind_gusts_10m,is_day&daily=sunrise,sunset&timezone=auto&forecast_days=14&models=best_match'
          ),
          axios.get(
            /*1050m sans freezing_level_height*/
            'https://api.open-meteo.com/v1/forecast?latitude=45.902370&longitude=6.420860&hourly=temperature_2m,snowfall,weather_code,wind_gusts_10m,is_day&daily=sunrise,sunset&timezone=auto&forecast_days=14&models=best_match'
          ),
          axios.get(
            /*1450m sans freezing_level_height*/
            'https://api.open-meteo.com/v1/forecast?latitude=45.807586&longitude=6.096495&hourly=temperature_2m,snowfall,weather_code,wind_gusts_10m,is_day&daily=sunrise,sunset&timezone=auto&forecast_days=14&models=best_match'
          ),
          axios.get(
            /*2500m sans freezing_level_height*/
            'https://api.open-meteo.com/v1/forecast?latitude=45.889909&longitude=6.495111&hourly=temperature_2m,snowfall,weather_code,wind_gusts_10m,is_day&daily=sunrise,sunset&timezone=auto&forecast_days=14&models=best_match'
          ),
          axios.get(
            /*450m*/
            'https://api.open-meteo.com/v1/forecast?latitude=45.9088&longitude=6.1257&hourly=freezing_level_height,is_day&daily=sunrise,sunset&timezone=auto&forecast_days=14&models=best_match'
          ),
        ]);

        // Construction des maps pour 1050, 1450, 2500 (sans freezing_level_height)
        const data1050Map = new Map();
        response1050.data.hourly.time.forEach((time: string, index: number) => {
          data1050Map.set(time, {
            temperature1050: response1050.data.hourly.temperature_2m[index],
            windGust1050: response1050.data.hourly.wind_gusts_10m[index],
            snowfall1050: response1050.data.hourly.snowfall[index] || 0,
            weatherCode: response1050.data.hourly.weather_code[index],
            is_day: response1050.data.hourly.is_day[index],
          });
        });

        const data1450Map = new Map();
        response1450.data.hourly.time.forEach((time: string, index: number) => {
          data1450Map.set(time, {
            temperature1450: response1450.data.hourly.temperature_2m[index],
            windGust1450: response1450.data.hourly.wind_gusts_10m[index],
            snowfall1450: response1450.data.hourly.snowfall[index] || 0,
            weatherCode: response1450.data.hourly.weather_code[index],
            is_day: response1450.data.hourly.is_day[index],
          });
        });

        const data2500Map = new Map();
        response2500.data.hourly.time.forEach((time: string, index: number) => {
          data2500Map.set(time, {
            temperature2500: response2500.data.hourly.temperature_2m[index],
            windGust2500: response2500.data.hourly.wind_gusts_10m[index],
            snowfall2500: response2500.data.hourly.snowfall[index] || 0,
            weatherCode: response2500.data.hourly.weather_code[index],
            is_day: response2500.data.hourly.is_day[index],
          });
        });

        // Construction du map pour 450m
        const data450Map = new Map();
        response450.data.hourly.time.forEach((time: string, index: number) => {
          data450Map.set(time, {
            freezing_level_height450: response450.data.hourly.freezing_level_height[index],
            is_day: response450.data.hourly.is_day[index],
          });
        });

        // Fusion des données horaires
        const combinedHourly = response1700.data.hourly.time.map((time: string, index: number) => {
          const map1050 = data1050Map.get(time) || {};
          const map1450 = data1450Map.get(time) || {};
          const map2500 = data2500Map.get(time) || {};
          const map450 = data450Map.get(time) || {};

          return {
            forecastStart: time,
            temperature1700: response1700.data.hourly.temperature_2m[index],
            windGust1700: response1700.data.hourly.wind_gusts_10m[index],

            temperature1050: map1050.temperature1050,
            windGust1050: map1050.windGust1050,

            temperature1450: map1450.temperature1450,
            windGust1450: map1450.windGust1450,

            temperature2500: map2500.temperature2500,
            windGust2500: map2500.windGust2500,

            weatherCode: response1700.data.hourly.weather_code[index],
            is_day: response1700.data.hourly.is_day[index],

            // Remplacer freezing_level_height1700 par freezing_level_height450
            freezing_level_height450: map450.freezing_level_height450,

            snowfall1700: response1700.data.hourly.snowfall[index] || 0,
            snowfall1050: map1050.snowfall1050 || 0,
            snowfall1450: map1450.snowfall1450 || 0,
            snowfall2500: map2500.snowfall2500 || 0,
          };
        });

        const transformedData = {
          forecastDaily: {
            days: response1700.data.daily.sunrise.map((sunrise: string, i: number) => ({
              forecastStart: response1700.data.daily.sunrise[i],
              forecastEnd: response1700.data.daily.sunset[i],
              sunrise: response1700.data.daily.sunrise[i],
              sunset: response1700.data.daily.sunset[i],
            })),
          },
          forecastHourly: {
            hours: combinedHourly,
          },
        };

        setWeatherData(transformedData);
      } catch (error) {
        console.error('Erreur lors de la récupération des données météo:', error);
        setMessage('Erreur lors de la récupération des données météo.');
        setMessageType('error');
      }
    };
    fetchWeatherData();
  }, []);

  // Calcul des totaux de neige sur 7 jours après la récupération des données météo
  useEffect(() => {
    if (!weatherData) return;

    const computeTotalSnowfall = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const sevenDaysLater = new Date(today);
      sevenDaysLater.setDate(today.getDate() + 7);

      const total1050 = weatherData.forecastHourly.hours.reduce((sum: number, hour: any) => {
        const forecastTime = new Date(hour.forecastStart);
        return forecastTime >= today && forecastTime < sevenDaysLater
          ? sum + (hour.snowfall1050 || 0)
          : sum;
      }, 0);

      const total1450 = weatherData.forecastHourly.hours.reduce((sum: number, hour: any) => {
        const forecastTime = new Date(hour.forecastStart);
        return forecastTime >= today && forecastTime < sevenDaysLater
          ? sum + (hour.snowfall1450 || 0)
          : sum;
      }, 0);

      const total1700 = weatherData.forecastHourly.hours.reduce((sum: number, hour: any) => {
        const forecastTime = new Date(hour.forecastStart);
        return forecastTime >= today && forecastTime < sevenDaysLater
          ? sum + (hour.snowfall1700 || 0)
          : sum;
      }, 0);

      const total2500 = weatherData.forecastHourly.hours.reduce((sum: number, hour: any) => {
        const forecastTime = new Date(hour.forecastStart);
        return forecastTime >= today && forecastTime < sevenDaysLater
          ? sum + (hour.snowfall2500 || 0)
          : sum;
      }, 0);

      setApiTotalSnow1050(roundToNearestInteger(total1050));
      setApiTotalSnow1450(roundToNearestInteger(total1450));
      setApiTotalSnow1700(roundToNearestInteger(total1700));
      setApiTotalSnow2500(roundToNearestInteger(total2500));
    };

    computeTotalSnowfall();
  }, [weatherData]);

  // Récupération des prévisions sauvegardées (dont le switch excludeSnowAccumulationFromAuto)
  useEffect(() => {
    const fetchSavedForecasts = async () => {
      try {
        const mainDocRef = doc(db, 'forecasts', 'expertForecastsMountainDefault1700');
        const mainDocSnap = await getDoc(mainDocRef);

        if (mainDocSnap.exists()) {
          const mainData = mainDocSnap.data() as Record<string, any>;

          // Récupérer les prévisions expertisées
          const forecastsData = { ...mainData };
          delete forecastsData['7dSnowAccumulation']; // On supprime le sous-objet (on va le gérer séparément)
          delete forecastsData['excludeSnowAccumulationFromAuto']; // Idem, on le gère en dehors
          setExpertForecasts(forecastsData as Record<string, ForecastData>);

          // Récupérer la valeur du switch si elle existe
          if (mainData.excludeSnowAccumulationFromAuto !== undefined) {
            setExcludeSnowAccumulationFromAuto(Boolean(mainData.excludeSnowAccumulationFromAuto));
          }

          // Récupérer les valeurs de neige éditées depuis le sous-document
          if (mainData['7dSnowAccumulation']) {
            const snowAccumulation = mainData['7dSnowAccumulation'];
            setSnowAccumulation7d1050(
              snowAccumulation['7dSnowAccumulation1050'] !== undefined
                ? roundToNearestInteger(snowAccumulation['7dSnowAccumulation1050'])
                : undefined
            );
            setSnowAccumulation7d1450(
              snowAccumulation['7dSnowAccumulation1450'] !== undefined
                ? roundToNearestInteger(snowAccumulation['7dSnowAccumulation1450'])
                : undefined
            );
            setSnowAccumulation7d1700(
              snowAccumulation['7dSnowAccumulation1700'] !== undefined
                ? roundToNearestInteger(snowAccumulation['7dSnowAccumulation1700'])
                : undefined
            );
            setSnowAccumulation7d2500(
              snowAccumulation['7dSnowAccumulation2500'] !== undefined
                ? roundToNearestInteger(snowAccumulation['7dSnowAccumulation2500'])
                : undefined
            );
          }
        } else {
          console.log("Aucune prévision trouvée dans 'expertForecastsMountainDefault1700'.");
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des prévisions sauvegardées:', error);
        setMessage('Erreur lors de la récupération des prévisions sauvegardées.');
        setMessageType('error');
      }
    };
    fetchSavedForecasts();
  }, []);

  // Réinitialiser les J9 à J13 (fiabilité "mauvaise") une seule fois
  useEffect(() => {
    if (weatherData && Object.keys(expertForecasts).length > 0 && !hasJ9J13ResetRef.current) {
      const daysToReset = ['J9', 'J10', 'J11', 'J12', 'J13'];
      daysToReset.forEach((dayNumber) => {
        resetExpertForecastForDay(dayNumber, 'mauvaise');
      });
      saveForecasts();
      hasJ9J13ResetRef.current = true;
    }
  }, [weatherData, expertForecasts, resetExpertForecastForDay, saveForecasts]);

  // Réinitialiser en mode auto lors du chargement
  useEffect(() => {
    if (weatherData && !hasVacationResetRef.current) {
      resetAllForecastsForVacationMode();
    }
  }, [weatherData, resetAllForecastsForVacationMode]);

  // Mettre à jour une prévision pour une période
  const updateExpertForecastForPeriod = (
    dayNumber: string,
    period: ForecastPeriod,
    updatedData: PeriodData
  ) => {
    setExpertForecasts((prev) => {
      const dayKey = `${dayNumber}-expert`;
      const existingDayData = prev[dayKey] || {
        morningData: { icon: getWeatherIcon(0, false) },
        afternoonData: { icon: getWeatherIcon(0, false) },
        eveningData: { icon: getWeatherIcon(0, false) },
        nightData: { icon: getWeatherIcon(0, false) },
        MountainBulletin: '',
        alertOrange: false,
        alertRed: false,
        reliability: 'bonne',
        sunrise: '',
        sunset: '',
        iso0morning: undefined,
        iso0afternoon: undefined,
        iso0evening: undefined,
        iso0night: undefined,
        modeAuto: true,
      };
      return {
        ...prev,
        [dayKey]: {
          ...existingDayData,
          [period]: updatedData,
        },
      };
    });
  };

  // Mettre à jour iso0morning, iso0afternoon, iso0evening, iso0night
  const updateExpertForecastForIso0 = (
    dayNumber: string,
    updatedIso0: string | undefined,
    type: 'morning' | 'afternoon' | 'evening' | 'night'
  ) => {
    setExpertForecasts((prev) => {
      const dayKey = `${dayNumber}-expert`;
      const existingDayData = prev[dayKey] || {
        morningData: { icon: getWeatherIcon(0, false) },
        afternoonData: { icon: getWeatherIcon(0, false) },
        eveningData: { icon: getWeatherIcon(0, false) },
        nightData: { icon: getWeatherIcon(0, false) },
        MountainBulletin: '',
        alertOrange: false,
        alertRed: false,
        reliability: 'bonne',
        sunrise: '',
        sunset: '',
        iso0morning: undefined,
        iso0afternoon: undefined,
        iso0evening: undefined,
        iso0night: undefined,
        modeAuto: true,
      };

      let newData: Partial<ForecastData> = {};
      if (type === 'morning') {
        newData = { iso0morning: updatedIso0 };
      } else if (type === 'afternoon') {
        newData = { iso0afternoon: updatedIso0 };
      } else if (type === 'evening') {
        newData = { iso0evening: updatedIso0 };
      } else if (type === 'night') {
        newData = { iso0night: updatedIso0 };
      }
      return {
        ...prev,
        [dayKey]: {
          ...existingDayData,
          ...newData,
        },
      };
    });
  };

  const openSidePanel = (dayNumber: string, period: ForecastPeriod) => {
    setEditingDay({ day: dayNumber, period });
    setSidePanelOpen(true);
  };

  // Décalage manuel
  const handleManualShift = async () => {
    setShiftLoading(true);
    setShiftMessage(null);
    setShiftMessageType(null);
    try {
      await axios.get('https://us-central1-annecymeteo-49ea3.cloudfunctions.net/shiftForecastsManual');
      setShiftMessage('Décalage des prévisions effectué avec succès.');
      setShiftMessageType('success');

      // Recharger depuis Firestore
      const mainDocRef = doc(db, 'forecasts', 'expertForecastsMountainDefault1700');
      const mainDocSnap = await getDoc(mainDocRef);
      if (mainDocSnap.exists()) {
        const mainData = mainDocSnap.data() as Record<string, any>;

        // Récupérer les prévisions expertisées
        const forecastsData = { ...mainData };
        delete forecastsData['7dSnowAccumulation'];
        delete forecastsData['excludeSnowAccumulationFromAuto'];
        setExpertForecasts(forecastsData as Record<string, ForecastData>);

        // Récupérer la valeur du switch
        if (mainData.excludeSnowAccumulationFromAuto !== undefined) {
          setExcludeSnowAccumulationFromAuto(Boolean(mainData.excludeSnowAccumulationFromAuto));
        }

        // Récupérer les valeurs de neige
        if (mainData['7dSnowAccumulation']) {
          const snowAccumulation = mainData['7dSnowAccumulation'];
          setSnowAccumulation7d1050(
            snowAccumulation['7dSnowAccumulation1050'] !== undefined
              ? roundToNearestInteger(snowAccumulation['7dSnowAccumulation1050'])
              : undefined
          );
          setSnowAccumulation7d1450(
            snowAccumulation['7dSnowAccumulation1450'] !== undefined
              ? roundToNearestInteger(snowAccumulation['7dSnowAccumulation1450'])
              : undefined
          );
          setSnowAccumulation7d1700(
            snowAccumulation['7dSnowAccumulation1700'] !== undefined
              ? roundToNearestInteger(snowAccumulation['7dSnowAccumulation1700'])
              : undefined
          );
          setSnowAccumulation7d2500(
            snowAccumulation['7dSnowAccumulation2500'] !== undefined
              ? roundToNearestInteger(snowAccumulation['7dSnowAccumulation2500'])
              : undefined
          );
        }
      } else {
        console.log('Aucune prévision sauvegardée trouvée.');
        setShiftMessage('Aucune prévision sauvegardée trouvée.');
        setShiftMessageType('error');
      }
    } catch (error) {
      console.error('Erreur lors du décalage manuel des prévisions:', error);
      setShiftMessage('Erreur lors du décalage des prévisions.');
      setShiftMessageType('error');
    } finally {
      setShiftLoading(false);
    }
  };

  if (!weatherData) {
    return <div>Chargement des données météo...</div>;
  }

  const { forecastDaily, forecastHourly } = weatherData;
  const currentDate = new Date().setHours(0, 0, 0, 0);
  const filteredDays = forecastDaily.days.filter((day: any) => {
    const dayDate = new Date(day.forecastStart).setHours(0, 0, 0, 0);
    return dayDate >= currentDate;
  });

  const startDate = new Date(filteredDays[0].forecastStart);
  startDate.setHours(0, 0, 0, 0);
  const fullDays = [...filteredDays];

  // On complète si l'API ne renvoie pas 14 jours
  while (fullDays.length < 14) {
    const lastDay = fullDays[fullDays.length - 1];
    const nextDayDate = new Date(lastDay.forecastStart);
    nextDayDate.setDate(nextDayDate.getDate() + 1);
    nextDayDate.setHours(0, 0, 0, 0);
    const nextDay = {
      forecastStart: nextDayDate.toISOString(),
      forecastEnd: nextDayDate.toISOString(),
      sunrise: nextDayDate.toISOString(),
      sunset: nextDayDate.toISOString(),
    };
    fullDays.push(nextDay);
  }

  // Calculer si tous les jours sont en mode auto
  const allDaysModeAuto =
    fullDays.length > 0 &&
    fullDays.every((day, index) => {
      const dayNumber = `J${index}`;
      return expertForecasts[`${dayNumber}-expert`]?.modeAuto === true;
    });

  // Mettre en place la fonction pour définir le mode auto de tous les jours
  const setAllDaysModeAuto = (modeAuto: boolean) => {
    setExpertForecasts((prev) => {
      const updatedForecasts = { ...prev };
      fullDays.forEach((day, index) => {
        const dayNumber = `J${index}`;
        if (!updatedForecasts[`${dayNumber}-expert`]) {
          updatedForecasts[`${dayNumber}-expert`] = {};
        }
        updatedForecasts[`${dayNumber}-expert`].modeAuto = modeAuto;
      });
      return updatedForecasts;
    });

    // Si le mode auto est activé, réinitialiser les prévisions si nécessaire
    if (modeAuto && !hasVacationResetRef.current) {
      resetAllForecastsForVacationMode();
    }
  };

  // ---- Rendu de chaque période (matin, aprèm, soirée, nuit)
  const renderDayPart = (
    label: string,
    data: PeriodData | undefined,
    isNight: boolean,
    isExpertised: boolean,
    onIconClick?: () => void,
    onDataChange?: (updatedData: PeriodData) => void
  ) => {
    if (!data) {
      return (
        <div className="day-part" key={label}>
          <h4>{label}</h4>
          <p>Données non disponibles</p>
        </div>
      );
    }

    const icon = data.icon;
    const roundedT1050 =
      data.temperature1050 !== undefined ? `${Math.round(data.temperature1050)}°` : 'N/A';
    const roundedT1450 =
      data.temperature1450 !== undefined ? `${Math.round(data.temperature1450)}°` : 'N/A';
    const roundedT1700 =
      data.temperature1700 !== undefined ? `${Math.round(data.temperature1700)}°` : 'N/A';
    const roundedT2500 =
      data.temperature2500 !== undefined ? `${Math.round(data.temperature2500)}°` : 'N/A';

    const roundedFF1050 =
      data.windGust1050 !== undefined ? `${Math.round(data.windGust1050)} km/h` : 'N/A';
    const roundedFF1450 =
      data.windGust1450 !== undefined ? `${Math.round(data.windGust1450)} km/h` : 'N/A';
    const roundedFF1700 =
      data.windGust1700 !== undefined ? `${Math.round(data.windGust1700)} km/h` : 'N/A';
    const roundedFF2500 =
      data.windGust2500 !== undefined ? `${Math.round(data.windGust2500)} km/h` : 'N/A';

    return (
      <div className="day-part" key={label}>
        <h4>{label}</h4>
        <img src={icon} alt="weather-icon" className="weather-icon" onClick={onIconClick} />

        {isExpertised && (
          <>
            {/* T1050 */}
            <div className="input-label-group">
              <label htmlFor={`temperature1050-${label}`}>T1050</label>
              <input
                id={`temperature1050-${label}`}
                type="number"
                step="1"
                value={data.temperature1050 !== undefined ? data.temperature1050 : ''}
                onChange={(e) => {
                  if (!onDataChange) return;
                  const val = e.target.value;
                  if (val === '') {
                    onDataChange({ ...data, temperature1050: undefined });
                  } else {
                    const parsed = parseFloat(val);
                    if (!isNaN(parsed)) {
                      onDataChange({ ...data, temperature1050: Math.round(parsed) });
                    }
                  }
                }}
                className="input-standard"
              />
            </div>

            {/* T1450 */}
            <div className="input-label-group">
              <label htmlFor={`temperature1450-${label}`}>T1450</label>
              <input
                id={`temperature1450-${label}`}
                type="number"
                step="1"
                value={data.temperature1450 !== undefined ? data.temperature1450 : ''}
                onChange={(e) => {
                  if (!onDataChange) return;
                  const val = e.target.value;
                  if (val === '') {
                    onDataChange({ ...data, temperature1450: undefined });
                  } else {
                    const parsed = parseFloat(val);
                    if (!isNaN(parsed)) {
                      onDataChange({ ...data, temperature1450: Math.round(parsed) });
                    }
                  }
                }}
                className="input-standard"
              />
            </div>

            {/* T1700 */}
            <div className="input-label-group">
              <label htmlFor={`temperature1700-${label}`}>T1700</label>
              <input
                id={`temperature1700-${label}`}
                type="number"
                step="1"
                value={data.temperature1700 !== undefined ? data.temperature1700 : ''}
                onChange={(e) => {
                  if (!onDataChange) return;
                  const val = e.target.value;
                  if (val === '') {
                    onDataChange({ ...data, temperature1700: undefined });
                  } else {
                    const parsed = parseFloat(val);
                    if (!isNaN(parsed)) {
                      onDataChange({ ...data, temperature1700: Math.round(parsed) });
                    }
                  }
                }}
                className="input-standard"
              />
            </div>

            {/* T2500 */}
            <div className="input-label-group">
              <label htmlFor={`temperature2500-${label}`}>T2500</label>
              <input
                id={`temperature2500-${label}`}
                type="number"
                step="1"
                value={data.temperature2500 !== undefined ? data.temperature2500 : ''}
                onChange={(e) => {
                  if (!onDataChange) return;
                  const val = e.target.value;
                  if (val === '') {
                    onDataChange({ ...data, temperature2500: undefined });
                  } else {
                    const parsed = parseFloat(val);
                    if (!isNaN(parsed)) {
                      onDataChange({ ...data, temperature2500: Math.round(parsed) });
                    }
                  }
                }}
                className="input-standard"
              />
            </div>

            {/* FF1050 */}
            <div className="input-label-group">
              <label htmlFor={`windGust1050-${label}`}>FF1050</label>
              <input
                id={`windGust1050-${label}`}
                type="number"
                step="1"
                value={data.windGust1050 !== undefined ? data.windGust1050 : ''}
                onChange={(e) => {
                  if (!onDataChange) return;
                  const val = e.target.value;
                  if (val === '') {
                    onDataChange({ ...data, windGust1050: undefined });
                  } else {
                    const parsed = parseFloat(val);
                    if (!isNaN(parsed)) {
                      onDataChange({ ...data, windGust1050: Math.round(parsed) });
                    }
                  }
                }}
                className="input-standard"
              />
            </div>

            {/* FF1450 */}
            <div className="input-label-group">
              <label htmlFor={`windGust1450-${label}`}>FF1450</label>
              <input
                id={`windGust1450-${label}`}
                type="number"
                step="1"
                value={data.windGust1450 !== undefined ? data.windGust1450 : ''}
                onChange={(e) => {
                  if (!onDataChange) return;
                  const val = e.target.value;
                  if (val === '') {
                    onDataChange({ ...data, windGust1450: undefined });
                  } else {
                    const parsed = parseFloat(val);
                    if (!isNaN(parsed)) {
                      onDataChange({ ...data, windGust1450: Math.round(parsed) });
                    }
                  }
                }}
                className="input-standard"
              />
            </div>

            {/* FF1700 */}
            <div className="input-label-group">
              <label htmlFor={`windGust1700-${label}`}>FF1700</label>
              <input
                id={`windGust1700-${label}`}
                type="number"
                step="1"
                value={data.windGust1700 !== undefined ? data.windGust1700 : ''}
                onChange={(e) => {
                  if (!onDataChange) return;
                  const val = e.target.value;
                  if (val === '') {
                    onDataChange({ ...data, windGust1700: undefined });
                  } else {
                    const parsed = parseFloat(val);
                    if (!isNaN(parsed)) {
                      onDataChange({ ...data, windGust1700: Math.round(parsed) });
                    }
                  }
                }}
                className="input-standard"
              />
            </div>

            {/* FF2500 */}
            <div className="input-label-group">
              <label htmlFor={`windGust2500-${label}`}>FF2500</label>
              <input
                id={`windGust2500-${label}`}
                type="number"
                step="1"
                value={data.windGust2500 !== undefined ? data.windGust2500 : ''}
                onChange={(e) => {
                  if (!onDataChange) return;
                  const val = e.target.value;
                  if (val === '') {
                    onDataChange({ ...data, windGust2500: undefined });
                  } else {
                    const parsed = parseFloat(val);
                    if (!isNaN(parsed)) {
                      onDataChange({ ...data, windGust2500: Math.round(parsed) });
                    }
                  }
                }}
                className="input-standard"
              />
            </div>
          </>
        )}

        {!isExpertised && (
          <>
            {/* Lecture seule pour T / FF */}
            <p>
              <strong>T1050</strong>
              <br />
              {roundedT1050}
            </p>
            <p>
              <strong>T1450</strong>
              <br />
              {roundedT1450}
            </p>
            <p>
              <strong>T1700</strong>
              <br />
              {roundedT1700}
            </p>
            <p>
              <strong>T2500</strong>
              <br />
              {roundedT2500}
            </p>

            <p>
              <strong>FF1050</strong>
              <br />
              {roundedFF1050}
            </p>
            <p>
              <strong>FF1450</strong>
              <br />
              {roundedFF1450}
            </p>
            <p>
              <strong>FF1700</strong>
              <br />
              {roundedFF1700}
            </p>
            <p>
              <strong>FF2500</strong>
              <br />
              {roundedFF2500}
            </p>
          </>
        )}
      </div>
    );
  };

  // Rendu d'un "jour" complet (matin, aprèm, soirée, nuit)
  const renderDay = (
    day: any,
    index: number,
    hourlyData: any[],
    startDate: Date,
    isExpertised: boolean,
    expertForecasts: Record<string, ForecastData>,
    resetExpertForecastForDay: (dayNumber: string, reliability?: ReliabilityType) => void,
    openSidePanel: (dayNumber: string, period: ForecastPeriod) => void,
    nextDayNightHours: any[]
  ) => {
    const dayStart = new Date(day.forecastStart).setHours(0, 0, 0, 0);
    const dayEnd = new Date(day.forecastStart).setHours(23, 59, 59, 999);
    const hoursForDay = hourlyData.filter((hour: any) => {
      const forecastTime = new Date(hour.forecastStart).getTime();
      return forecastTime >= dayStart && forecastTime <= dayEnd;
    });

    if (!hoursForDay || hoursForDay.length === 0) {
      return (
        <div key={`${index}-${isExpertised ? 'expert' : 'non-expert'}`} className="day-block">
          <h3>
            Jour {index + 1} - {new Date(day.forecastStart).toLocaleDateString()}
          </h3>
          <p>Données horaires non disponibles pour ce jour.</p>
        </div>
      );
    }

    const iso0morningRaw = hoursForDay.find(
      (h: any) => new Date(h.forecastStart).getHours() === 9
    )?.freezing_level_height450;
    const iso0morning =
      iso0morningRaw !== undefined ? roundToNearest100(iso0morningRaw).toString() : undefined;

    const iso0afternoonRaw = hoursForDay.find(
      (h: any) => new Date(h.forecastStart).getHours() === 15
    )?.freezing_level_height450;
    const iso0afternoon =
      iso0afternoonRaw !== undefined ? roundToNearest100(iso0afternoonRaw).toString() : undefined;

    const iso0eveningRaw = hoursForDay.find(
      (h: any) => new Date(h.forecastStart).getHours() === 21
    )?.freezing_level_height450;
    const iso0evening =
      iso0eveningRaw !== undefined ? roundToNearest100(iso0eveningRaw).toString() : undefined;

    let iso0night: string | undefined;
    if (fullDays[index + 1]) {
      const nextApiDay = fullDays[index + 1];
      const nextDayDate = new Date(nextApiDay.forecastStart);
      nextDayDate.setHours(0, 0, 0, 0);
      const dayStartNext = nextDayDate.getTime();
      const dayEndNext = dayStartNext + 6 * 60 * 60 * 1000 - 1;
      const hoursForNextDay = hourlyData.filter((hour: any) => {
        const fcTime = new Date(hour.forecastStart).getTime();
        return fcTime >= dayStartNext && fcTime <= dayEndNext;
      });
      const iso0nightRaw = hoursForNextDay.find(
        (h: any) => new Date(h.forecastStart).getHours() === 3
      )?.freezing_level_height450;
      iso0night =
        iso0nightRaw !== undefined ? roundToNearest100(iso0nightRaw).toString() : undefined;
    }

    const morningData = getPeriodData(hoursForDay, 6, 11, 'morning', false);
    const afternoonData = getPeriodData(hoursForDay, 12, 17, 'afternoon', false);
    const isEveningNight = isSunsetBefore20(day.sunset);
    const eveningData = getPeriodData(hoursForDay, 18, 23, 'avg', isEveningNight);

    const nightData =
      nextDayNightHours.length > 0
        ? getPeriodData(nextDayNightHours, 0, 5, 'avg', true)
        : {
            temperature1050: undefined,
            temperature1450: undefined,
            temperature1700: undefined,
            temperature2500: undefined,
            windGust1050: undefined,
            windGust1450: undefined,
            windGust1700: undefined,
            windGust2500: undefined,
            icon: getWeatherIcon(0, true),
            snowfall1050: 0,
            snowfall1450: 0,
            snowfall1700: 0,
            snowfall2500: 0,
          };

    const daysDifference = Math.floor(
      (new Date(day.forecastStart).getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    const dayNumber = `J${daysDifference}`;

    const forecastKey = `${dayNumber}-expert`;
    const expertData = expertForecasts[forecastKey] || {};

    // Fusion des données
    const forecastData: ForecastData = {
      morningData: isExpertised ? { ...morningData, ...expertData.morningData } : morningData,
      afternoonData: isExpertised
        ? { ...afternoonData, ...expertData.afternoonData }
        : afternoonData,
      eveningData: isExpertised ? { ...eveningData, ...expertData.eveningData } : eveningData,
      nightData: isExpertised ? { ...nightData, ...expertData.nightData } : nightData,
      MountainBulletin: isExpertised ? expertData.MountainBulletin || '' : '',
      alertOrange: isExpertised ? expertData.alertOrange || false : false,
      alertRed: isExpertised ? expertData.alertRed || false : false,
      reliability: isExpertised ? expertData.reliability || 'bonne' : undefined,
      sunrise: expertData.sunrise || day.sunrise,
      sunset: expertData.sunset || day.sunset,
      iso0morning: isExpertised ? expertData.iso0morning || iso0morning : iso0morning,
      iso0afternoon: isExpertised ? expertData.iso0afternoon || iso0afternoon : iso0afternoon,
      iso0evening: isExpertised ? expertData.iso0evening || iso0evening : iso0evening,
      iso0night: isExpertised ? expertData.iso0night || iso0night : iso0night,
      modeAuto: isExpertised ? expertData.modeAuto || false : false,
    };

    const iso0morningValue = forecastData.iso0morning ?? '';
    const iso0afternoonValue = forecastData.iso0afternoon ?? '';
    const iso0eveningValue = forecastData.iso0evening ?? '';
    const iso0nightValue = forecastData.iso0night ?? '';

    // Cumul journalier de neige pour chaque altitude
    const dailySnow1050 = hoursForDay.reduce((acc: number, h: any) => acc + (h.snowfall1050 || 0), 0);
    const dailySnow1450 = hoursForDay.reduce((acc: number, h: any) => acc + (h.snowfall1450 || 0), 0);
    const dailySnow1700 = hoursForDay.reduce((acc: number, h: any) => acc + (h.snowfall1700 || 0), 0);
    const dailySnow2500 = hoursForDay.reduce((acc: number, h: any) => acc + (h.snowfall2500 || 0), 0);

    return (
      <div
        key={`${index}-${isExpertised ? 'expert' : 'non-expert'}`}
        className={`day-block ${isExpertised ? 'expert-block' : ''} ${
          forecastData.alertOrange ? 'alert-orange' : ''
        } ${forecastData.alertRed ? 'alert-red' : ''}`}
      >
        <h3>
          {dayNumber} -{' '}
          {new Date(day.forecastStart).toLocaleDateString(undefined, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}
        </h3>

        {isExpertised && (
          <div className="alert-switches-and-reliability">
            {/* Switch Orange */}
            <label className="switch switch-orange">
              <input
                type="checkbox"
                checked={forecastData.alertOrange || false}
                onChange={() => {
                  setExpertForecasts((prev) => {
                    const dKey = `${dayNumber}-expert`;
                    const existingDayData = prev[dKey] || {};
                    return {
                      ...prev,
                      [dKey]: {
                        ...existingDayData,
                        alertOrange: !forecastData.alertOrange,
                      },
                    };
                  });
                }}
              />
              <span className="slider round"></span>
            </label>
            <span>Alerte Orange</span>

            {/* Switch Rouge */}
            <label className="switch switch-red">
              <input
                type="checkbox"
                checked={forecastData.alertRed || false}
                onChange={() => {
                  setExpertForecasts((prev) => {
                    const dKey = `${dayNumber}-expert`;
                    const existingDayData = prev[dKey] || {};
                    return {
                      ...prev,
                      [dKey]: {
                        ...existingDayData,
                        alertRed: !forecastData.alertRed,
                      },
                    };
                  });
                }}
              />
              <span className="slider round"></span>
            </label>
            <span>Alerte Rouge</span>

            {/* Fiabilité */}
            <div className="reliability-selector">
              <label>
                Fiabilité :
                <select
                  value={forecastData.reliability || 'bonne'}
                  onChange={(e) => {
                    const selected = e.target.value as ReliabilityType;
                    setExpertForecasts((prev) => {
                      const dKey = `${dayNumber}-expert`;
                      const existingDayData = prev[dKey] || {};
                      return {
                        ...prev,
                        [dKey]: {
                          ...existingDayData,
                          reliability: selected,
                        },
                      };
                    });
                  }}
                >
                  <option value="bonne">Bonne</option>
                  <option value="moyenne">Moyenne</option>
                  <option value="mauvaise">Mauvaise</option>
                </select>
              </label>
            </div>
          </div>
        )}

        {/* Switch Auto Individuel */}
        {isExpertised && (
          <div className="mode-auto-switch">
            <label className="switch">
              <input
                type="checkbox"
                checked={forecastData.modeAuto === true}
                onChange={(e) => {
                  const newModeAuto = e.target.checked;
                  setExpertForecasts((prev) => ({
                    ...prev,
                    [forecastKey]: {
                      ...prev[forecastKey],
                      modeAuto: newModeAuto,
                    },
                  }));
                  if (newModeAuto) {
                    resetExpertForecastForDay(dayNumber, forecastData.reliability);
                  }
                }}
              />
              <span className="slider round"></span>
            </label>
            <span>Mode auto</span>
          </div>
        )}

        <div className="prevision">
          {renderDayPart(
            'Matin',
            forecastData.morningData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'morningData') : undefined,
            isExpertised
              ? (updated) => updateExpertForecastForPeriod(dayNumber, 'morningData', updated)
              : undefined
          )}
          {renderDayPart(
            'Aprèm',
            forecastData.afternoonData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'afternoonData') : undefined,
            isExpertised
              ? (updated) => updateExpertForecastForPeriod(dayNumber, 'afternoonData', updated)
              : undefined
          )}
          {renderDayPart(
            'Soirée',
            forecastData.eveningData,
            false,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'eveningData') : undefined,
            isExpertised
              ? (updated) => updateExpertForecastForPeriod(dayNumber, 'eveningData', updated)
              : undefined
          )}
          {renderDayPart(
            'Nuit',
            forecastData.nightData,
            true,
            isExpertised,
            isExpertised ? () => openSidePanel(dayNumber, 'nightData') : undefined,
            isExpertised
              ? (updated) => updateExpertForecastForPeriod(dayNumber, 'nightData', updated)
              : undefined
          )}

          {/* Iso 0°C */}
          <div className="iso0-field">
            {isExpertised ? (
              <>
                <div className="input-label-group">
                  <label htmlFor={`iso0morning-${dayNumber}`}>Iso0</label>
                  <input
                    id={`iso0morning-${dayNumber}`}
                    type="text"
                    value={iso0morningValue}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      if (!val) {
                        updateExpertForecastForIso0(dayNumber, undefined, 'morning');
                      } else {
                        updateExpertForecastForIso0(dayNumber, val, 'morning');
                      }
                    }}
                    className="input-standard"
                    placeholder="Altitude Matin"
                  />
                </div>
                <div className="input-label-group">
                  <label htmlFor={`iso0afternoon-${dayNumber}`}>Iso0</label>
                  <input
                    id={`iso0afternoon-${dayNumber}`}
                    type="text"
                    value={iso0afternoonValue}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      if (!val) {
                        updateExpertForecastForIso0(dayNumber, undefined, 'afternoon');
                      } else {
                        updateExpertForecastForIso0(dayNumber, val, 'afternoon');
                      }
                    }}
                    className="input-standard"
                    placeholder="Altitude Après-midi"
                  />
                </div>
                <div className="input-label-group">
                  <label htmlFor={`iso0evening-${dayNumber}`}>Iso0</label>
                  <input
                    id={`iso0evening-${dayNumber}`}
                    type="text"
                    value={iso0eveningValue}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      if (!val) {
                        updateExpertForecastForIso0(dayNumber, undefined, 'evening');
                      } else {
                        updateExpertForecastForIso0(dayNumber, val, 'evening');
                      }
                    }}
                    className="input-standard"
                    placeholder="Altitude Soir"
                  />
                </div>
                <div className="input-label-group">
                  <label htmlFor={`iso0night-${dayNumber}`}>Iso0</label>
                  <input
                    id={`iso0night-${dayNumber}`}
                    type="text"
                    value={iso0nightValue}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      if (!val) {
                        updateExpertForecastForIso0(dayNumber, undefined, 'night');
                      } else {
                        updateExpertForecastForIso0(dayNumber, val, 'night');
                      }
                    }}
                    className="input-standard"
                    placeholder="Altitude Nuit"
                  />
                </div>
              </>
            ) : (
              <p>
                {forecastData.iso0morning !== undefined &&
                forecastData.iso0afternoon !== undefined &&
                forecastData.iso0evening !== undefined &&
                forecastData.iso0night !== undefined
                  ? `${forecastData.iso0morning}m / ${forecastData.iso0afternoon}m / ${forecastData.iso0evening}m / ${forecastData.iso0night}m`
                  : 'N/A'}
              </p>
            )}
          </div>
        </div>

        {isExpertised && (
          <>
            <button onClick={() => resetExpertForecastForDay(dayNumber)}>
              Réinitialiser avec API
            </button>
            <textarea
              value={forecastData.MountainBulletin || ''}
              onChange={(e) => {
                const newBulletin = e.target.value;
                setExpertForecasts((prev) => {
                  const dKey = `${dayNumber}-expert`;
                  const existingDayData = prev[dKey] || {};
                  return {
                    ...prev,
                    [dKey]: {
                      ...existingDayData,
                      MountainBulletin: newBulletin,
                    },
                  };
                });
              }}
              placeholder="Écrire le bulletin pour ce jour"
              style={{ overflow: 'hidden', resize: 'none' }}
              className="bulletin-textarea"
            />
          </>
        )}

        {/* Cumul de neige journalier (lecture seule) */}
        {!isExpertised && (
          <div className="daily-snowfall">
            <h4>Cumul journalier</h4>
            <p>SN1050 : {formatSnowfall(dailySnow1050)}</p>
            <p>SN1450 : {formatSnowfall(dailySnow1450)}</p>
            <p>SN1700 : {formatSnowfall(dailySnow1700)}</p>
            <p>SN2500 : {formatSnowfall(dailySnow2500)}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="prevision-page">
      <Header />

      {/* Cumul de neige sur 7 jours (EDITABLE) */}
      <SnowAccumulation
        apiSnow1050Total={apiTotalSnow1050}
        apiSnow1450Total={apiTotalSnow1450}
        apiSnow1700Total={apiTotalSnow1700}
        apiSnow2500Total={apiTotalSnow2500}
        snowAccumulation7d1050={snowAccumulation7d1050}
        setSnowAccumulation7d1050={setSnowAccumulation7d1050}
        snowAccumulation7d1450={snowAccumulation7d1450}
        setSnowAccumulation7d1450={setSnowAccumulation7d1450}
        snowAccumulation7d1700={snowAccumulation7d1700}
        setSnowAccumulation7d1700={setSnowAccumulation7d1700}
        snowAccumulation7d2500={snowAccumulation7d2500}
        setSnowAccumulation7d2500={setSnowAccumulation7d2500}
        excludeSnowAccumulationFromAuto={excludeSnowAccumulationFromAuto}
        setExcludeSnowAccumulationFromAuto={setExcludeSnowAccumulationFromAuto}
      />

      <span className="title">Semnoz 1700</span>
      {/* Switch "Mode auto" global */}
      <div className="vacation-mode-switch">
        <label className="switch">
          <input
            type="checkbox"
            checked={allDaysModeAuto}
            onChange={(e) => setAllDaysModeAuto(e.target.checked)}
          />
          <span className="slider round"></span>
        </label>
        <span>Mode auto (Tous les jours)</span>
      </div>

      <div className="prevision-container">
        {fullDays.map((day: any, index: number) => {
          // Heures du lendemain (00h-05h59) pour la nuit
          const nextDay = fullDays[index + 1];
          const nextDayNightHours = nextDay
            ? forecastHourly.hours.filter((hour: any) => {
                const fcTime = new Date(hour.forecastStart);
                const nextDayDate = new Date(nextDay.forecastStart);
                return (
                  fcTime.getFullYear() === nextDayDate.getFullYear() &&
                  fcTime.getMonth() === nextDayDate.getMonth() &&
                  fcTime.getDate() === nextDayDate.getDate() &&
                  fcTime.getHours() >= 0 &&
                  fcTime.getHours() < 6
                );
              })
            : [];

          return (
            <div key={index} className="combined-day-block">
              {/* Jour "brut" (non expertisé) */}
              {renderDay(
                day,
                index,
                forecastHourly.hours,
                startDate,
                false,
                expertForecasts,
                resetExpertForecastForDay,
                openSidePanel,
                nextDayNightHours
              )}
              {/* Jour "expertisé" */}
              {renderDay(
                day,
                index,
                forecastHourly.hours,
                startDate,
                true,
                expertForecasts,
                resetExpertForecastForDay,
                openSidePanel,
                nextDayNightHours
              )}
            </div>
          );
        })}

        {/* Side panel (choix des icônes) */}
        {sidePanelOpen && editingDay && (
          <div ref={sidePanelRef} className="side-panel">
            <h3>Choisir une icône</h3>
            <div className="icon-grid">
              {(() => {
                const { day, period } = editingDay;
                const dayKey = `${day}-expert`;
                const currentForecast = expertForecasts[dayKey] || {};
                let availableIcons: string[] = [];

                if (period === 'morningData' || period === 'afternoonData') {
                  availableIcons = dayIcons;
                } else if (period === 'eveningData') {
                  const sunset =
                    currentForecast.sunset ||
                    weatherData.forecastDaily.days[parseInt(day.substring(1), 10)].sunset;
                  const isEveningNight = isSunsetBefore20(sunset);
                  availableIcons = isEveningNight ? nightIcons : dayIcons;
                } else if (period === 'nightData') {
                  availableIcons = nightIcons;
                }

                return availableIcons.map((iconPath, idx) => (
                  <img
                    key={idx}
                    src={iconPath}
                    alt={`Icon ${idx}`}
                    className="icon-choice"
                    onClick={() => {
                      setExpertForecasts((prev) => {
                        const existingDayData = prev[dayKey] || {
                          morningData: { icon: getWeatherIcon(0, false) },
                          afternoonData: { icon: getWeatherIcon(0, false) },
                          eveningData: { icon: getWeatherIcon(0, false) },
                          nightData: { icon: getWeatherIcon(0, false) },
                          MountainBulletin: '',
                          alertOrange: false,
                          alertRed: false,
                          reliability: 'bonne',
                          sunrise: '',
                          sunset: '',
                          iso0morning: undefined,
                          iso0afternoon: undefined,
                          iso0evening: undefined,
                          iso0night: undefined,
                          modeAuto: true,
                        };
                        const existingPeriodData: PeriodData =
                          existingDayData[period] || {
                            temperature1050: undefined,
                            temperature1450: undefined,
                            temperature1700: undefined,
                            temperature2500: undefined,
                            windGust1050: undefined,
                            windGust1450: undefined,
                            windGust1700: undefined,
                            windGust2500: undefined,
                            icon: getWeatherIcon(0, false),
                            snowfall1050: 0,
                            snowfall1450: 0,
                            snowfall1700: 0,
                            snowfall2500: 0,
                          };

                        const updatedPeriodData: PeriodData = {
                          ...existingPeriodData,
                          icon: iconPath,
                        };

                        return {
                          ...prev,
                          [dayKey]: {
                            ...existingDayData,
                            [period]: updatedPeriodData,
                          },
                        };
                      });
                      setSidePanelOpen(false);
                    }}
                  />
                ));
              })()}
            </div>
          </div>
        )}

        {/* Bouton de décalage manuel */}
        <div className="manual-shift-button">
          <button onClick={handleManualShift} disabled={shiftLoading}>
            {shiftLoading ? <div className="loader"></div> : 'Forcer le décalage des prévisions'}
          </button>
        </div>

        {/* Bouton "Enregistrer" */}
        <div className="bandeau-floating-button">
          <button className="floating-button" onClick={() => saveForecasts()} disabled={loading}>
            {loading ? <div className="loader"></div> : 'Enregistrer'}
          </button>
        </div>

        {/* Message bannière (décalage manuel) */}
        {shiftMessage && <div className={`message-banner ${shiftMessageType}`}>{shiftMessage}</div>}

        {/* Message bannière principal */}
        {message && <div className={`message-banner ${messageType}`}>{message}</div>}
      </div>
    </div>
  );
};

export default PrevisionsMontagne;