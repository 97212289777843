// /Users/corentinbrgn/adminannecymeteo/frontend/src/Exports.tsx

import React, { useEffect, useState, useRef } from 'react';
import { getDoc, doc } from 'firebase/firestore';
import { db } from './firebase'; // Assurez-vous que ce chemin est correct
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FaFilePdf, FaImage, FaCopy } from 'react-icons/fa';
import Header from './Header'; // Assurez-vous que ce composant existe
import './Previsions.css'; // Utilisation de Previsions.css pour les styles

interface PeriodData {
  temperature?: number;
  windGust?: number;
  icon: string;
}

type ReliabilityType = 'bonne' | 'moyenne' | 'mauvaise';

interface ForecastData {
  morningData?: PeriodData;
  afternoonData?: PeriodData;
  eveningData?: PeriodData;
  nightData?: PeriodData;
  bulletin?: string;
  alertOrange?: boolean;
  alertRed?: boolean;
  reliability?: ReliabilityType;
  sunrise?: string;
  sunset?: string;
  iso0min?: string;
  iso0max?: string;
}

const Exports: React.FC = () => {
  const [expertForecasts, setExpertForecasts] = useState<Record<string, ForecastData>>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);

  // Références pour les sections à exporter
  const forecastRef = useRef<HTMLDivElement>(null);
  const recapRef = useRef<HTMLDivElement>(null);
  const weekendRef = useRef<HTMLDivElement>(null);
  const allBulletinsRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const fetchSavedForecasts = async () => {
      try {
        const docRef = doc(db, 'forecasts', 'expertForecasts');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setExpertForecasts(docSnap.data() as Record<string, ForecastData>);
        } else {
          console.log('Aucune prévision sauvegardée trouvée!');
          setMessage('Aucune prévision sauvegardée trouvée.');
          setMessageType('error');
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des prévisions sauvegardées:', error);
        setMessage('Erreur lors de la récupération des prévisions sauvegardées.');
        setMessageType('error');
      }
    };

    fetchSavedForecasts();
  }, []);

  // Fonction pour obtenir la date correspondant à J0, J1, etc.
  const getDateForDay = (dayNumber: number): string => {
    const date = new Date();
    date.setDate(date.getDate() + dayNumber);
    return date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  };

  // Fonction pour exporter une section en PDF
  const exportToPDF = async (elementRef: React.RefObject<HTMLDivElement>, filename: string) => {
    if (!elementRef.current) return;

    setLoading(true);
    try {
      const canvas = await html2canvas(elementRef.current, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${filename}.pdf`);
      setMessage(`Export PDF de ${filename} réussi !`);
      setMessageType('success');
    } catch (error) {
      console.error('Erreur lors de l\'export PDF:', error);
      setMessage(`Erreur lors de l'export PDF de ${filename}.`);
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour exporter une section en PNG
  const exportToPNG = async (elementRef: React.RefObject<HTMLDivElement>, filename: string) => {
    if (!elementRef.current) return;

    setLoading(true);
    try {
      const canvas = await html2canvas(elementRef.current, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');

      const link = document.createElement('a');
      link.href = imgData;
      link.download = `${filename}.png`;
      link.click();

      setMessage(`Export PNG de ${filename} réussi !`);
      setMessageType('success');
    } catch (error) {
      console.error('Erreur lors de l\'export PNG:', error);
      setMessage(`Erreur lors de l'export PNG de ${filename}.`);
      setMessageType('error');
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour copier les bulletins dans le presse-papiers
  const copyAllBulletins = () => {
    const allBulletins = Object.entries(expertForecasts)
      .filter(([key, forecast]) => forecast.bulletin)
      .sort(([a], [b]) => {
        const numA = parseInt(a.replace('J', '').replace('-expert', ''));
        const numB = parseInt(b.replace('J', '').replace('-expert', ''));
        return numA - numB;
      })
      .map(([key, forecast]) => {
        const dayNumber = parseInt(key.replace('J', '').replace('-expert', ''));
        const formattedDate = getDateForDay(dayNumber);
        return `Date: ${formattedDate}\nBulletin J${dayNumber}: ${forecast.bulletin}`;
      })
      .join('\n\n');

    navigator.clipboard.writeText(allBulletins)
      .then(() => {
        setMessage('Tous les bulletins ont été copiés dans le presse-papiers.');
        setMessageType('success');
      })
      .catch(() => {
        setMessage('Erreur lors de la copie des bulletins.');
        setMessageType('error');
      });
  };

  // Fonction pour générer le récapitulatif des prévisions
  const generateRecap = () => {
    const sortedForecasts = Object.entries(expertForecasts)
      .filter(([key]) => /^J[0-7]-expert$/.test(key))
      .sort(([a], [b]) => {
        const numA = parseInt(a.replace('J', '').replace('-expert', ''));
        const numB = parseInt(b.replace('J', '').replace('-expert', ''));
        return numA - numB;
      })
      .map(([key, data]) => ({
        day: key.replace('-expert', ''),
        ...data,
      }));
    return sortedForecasts;
  };

  // Fonction pour obtenir les prévisions du week-end
  const getWeekendForecasts = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Dimanche) à 6 (Samedi)
    const daysUntilWeekend = dayOfWeek === 6 ? 0 : 6 - dayOfWeek;
    const weekendDays = [];

    for (let i = 0; i < 2; i++) {
      const dayNumber = daysUntilWeekend + i;
      const dayKey = `J${dayNumber}-expert`; // Ajustement de la clé
      if (expertForecasts[dayKey]) {
        weekendDays.push({
          day: dayKey.replace('-expert', ''),
          date: getDateForDay(dayNumber),
          ...expertForecasts[dayKey],
        });
      }
    }

    return weekendDays;
  };

  return (
    <div className="exports-page">
      <Header />

      <div className="prevision-container">
        {/* Section des prévisions quotidiennes */}
        <div className="combined-day-block">
          <h2>Prévisions Météo Journalières (J0-expert à J7-expert)</h2>
          <div ref={forecastRef} className="prevision-container">
            {Object.entries(expertForecasts)
              .filter(([key]) => /^J[0-7]-expert$/.test(key))
              .sort(([a], [b]) => {
                const numA = parseInt(a.replace('J', '').replace('-expert', ''));
                const numB = parseInt(b.replace('J', '').replace('-expert', ''));
                return numA - numB;
              })
              .map(([key, data]) => {
                const dayNumber = parseInt(key.replace('J', '').replace('-expert', ''));
                const formattedDate = getDateForDay(dayNumber);
                return (
                  <div key={key} className="day-block">
                    <h3>J{dayNumber} - {formattedDate}</h3>
                    <div className="prevision">
                      {data.morningData && (
                        <div className="day-part">
                          <img src={data.morningData.icon} alt="Matin" className="weather-icon" />
                          <h4>Matin</h4>
                          <p>Température: {data.morningData.temperature ?? 'N/A'}°C</p>
                          <p>Rafales: {data.morningData.windGust ?? 'N/A'} km/h</p>
                        </div>
                      )}
                      {data.afternoonData && (
                        <div className="day-part">
                          <img src={data.afternoonData.icon} alt="Après-midi" className="weather-icon" />
                          <h4>Après-midi</h4>
                          <p>Température: {data.afternoonData.temperature ?? 'N/A'}°C</p>
                          <p>Rafales: {data.afternoonData.windGust ?? 'N/A'} km/h</p>
                        </div>
                      )}
                      {data.eveningData && (
                        <div className="day-part">
                          <img src={data.eveningData.icon} alt="Soirée" className="weather-icon" />
                          <h4>Soirée</h4>
                          <p>Température: {data.eveningData.temperature ?? 'N/A'}°C</p>
                          <p>Rafales: {data.eveningData.windGust ?? 'N/A'} km/h</p>
                        </div>
                      )}
                      {data.nightData && (
                        <div className="day-part">
                          <img src={data.nightData.icon} alt="Nuit" className="weather-icon" />
                          <h4>Nuit</h4>
                          <p>Température: {data.nightData.temperature ?? 'N/A'}°C</p>
                          <p>Rafales: {data.nightData.windGust ?? 'N/A'} km/h</p>
                        </div>
                      )}
                    </div>
                    <div className="export-buttons">
                      <button onClick={() => exportToPDF(forecastRef, `Prevision_J${dayNumber}`)}>
                        <FaFilePdf /> PDF
                      </button>
                      <button onClick={() => exportToPNG(forecastRef, `Prevision_J${dayNumber}`)}>
                        <FaImage /> PNG
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        {/* Section du récapitulatif */}
        <div className="combined-day-block">
          <h2>Récapitulatif des Prévisions (J0 à J7)</h2>
          <div ref={recapRef} className="prevision-container">
            {generateRecap().map((forecast) => {
              const dayNumber = parseInt(forecast.day.replace('J', ''));
              const formattedDate = getDateForDay(dayNumber);
              return (
                <div key={forecast.day} className="day-block">
                  <h3>J{dayNumber} - {formattedDate}</h3>
                  <p>Bulletin: {forecast.bulletin ?? 'N/A'}</p>
                  <p>Fiabilité: {forecast.reliability ?? 'N/A'}</p>
                  {forecast.alertOrange && <p className="alert-orange">Alerte Orange</p>}
                  {forecast.alertRed && <p className="alert-red">Alerte Rouge</p>}
                  <div className="export-buttons">
                    <button onClick={() => exportToPDF(recapRef, `Recap_Previsions_J${dayNumber}`)}>
                      <FaFilePdf /> PDF
                    </button>
                    <button onClick={() => exportToPNG(recapRef, `Recap_Previsions_J${dayNumber}`)}>
                      <FaImage /> PNG
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="export-buttons">
            <button onClick={() => exportToPDF(recapRef, 'Recap_Previsions')}>
              <FaFilePdf /> Exporter en PDF
            </button>
            <button onClick={() => exportToPNG(recapRef, 'Recap_Previsions')}>
              <FaImage /> Exporter en PNG
            </button>
          </div>
        </div>

        {/* Section des prévisions pour le week-end */}
        <div className="combined-day-block">
          <h2>Prévisions Météo pour le Week-end</h2>
          <div ref={weekendRef} className="prevision-container">
            {getWeekendForecasts().map((forecast) => {
              const dayNumber = parseInt(forecast.day.replace('J', ''));
              return (
                <div key={forecast.day} className="day-block">
                  <h3>J{dayNumber} - {forecast.date}</h3>
                  <div className="prevision">
                    {forecast.morningData && (
                      <div className="day-part">
                        <img src={forecast.morningData.icon} alt="Matin" className="weather-icon" />
                        <h4>Matin</h4>
                        <p>Température: {forecast.morningData.temperature ?? 'N/A'}°C</p>
                        <p>Rafales: {forecast.morningData.windGust ?? 'N/A'} km/h</p>
                      </div>
                    )}
                    {forecast.afternoonData && (
                      <div className="day-part">
                        <img src={forecast.afternoonData.icon} alt="Après-midi" className="weather-icon" />
                        <h4>Après-midi</h4>
                        <p>Température: {forecast.afternoonData.temperature ?? 'N/A'}°C</p>
                        <p>Rafales: {forecast.afternoonData.windGust ?? 'N/A'} km/h</p>
                      </div>
                    )}
                    {forecast.eveningData && (
                      <div className="day-part">
                        <img src={forecast.eveningData.icon} alt="Soirée" className="weather-icon" />
                        <h4>Soirée</h4>
                        <p>Température: {forecast.eveningData.temperature ?? 'N/A'}°C</p>
                        <p>Rafales: {forecast.eveningData.windGust ?? 'N/A'} km/h</p>
                      </div>
                    )}
                    {forecast.nightData && (
                      <div className="day-part">
                        <img src={forecast.nightData.icon} alt="Nuit" className="weather-icon" />
                        <h4>Nuit</h4>
                        <p>Température: {forecast.nightData.temperature ?? 'N/A'}°C</p>
                        <p>Rafales: {forecast.nightData.windGust ?? 'N/A'} km/h</p>
                      </div>
                    )}
                  </div>
                  <div className="export-buttons">
                    <button onClick={() => exportToPDF(weekendRef, `Previsions_Weekend_J${dayNumber}`)}>
                      <FaFilePdf /> PDF
                    </button>
                    <button onClick={() => exportToPNG(weekendRef, `Previsions_Weekend_J${dayNumber}`)}>
                      <FaImage /> PNG
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="export-buttons">
            <button onClick={() => exportToPDF(weekendRef, 'Previsions_Weekend')}>
              <FaFilePdf /> Exporter en PDF
            </button>
            <button onClick={() => exportToPNG(weekendRef, 'Previsions_Weekend')}>
              <FaImage /> Exporter en PNG
            </button>
          </div>
        </div>

        {/* Section d'exportation des bulletins */}
        <div className="combined-day-block">
    <h2>Exporter Tous les Bulletins</h2>
    <textarea
      ref={allBulletinsRef}
      className="bulletin-textarea"
      readOnly
      value={`Resume les prévisions météo ci-dessous :\n\n${Object.entries(expertForecasts)
        .filter(([key, forecast]) => forecast.bulletin)
        .sort(([a], [b]) => {
          const numA = parseInt(a.replace('J', '').replace('-expert', ''));
          const numB = parseInt(b.replace('J', '').replace('-expert', ''));
          return numA - numB;
        })
        .map(([key, forecast]) => {
          const dayNumber = parseInt(key.replace('J', '').replace('-expert', ''));
          const formattedDate = getDateForDay(dayNumber);
          return `Date: ${formattedDate}\nBulletin J${dayNumber}: ${forecast.bulletin}`;
        })
        .join('\n\n')}`}
    />
    <div className="export-buttons">
      <button onClick={copyAllBulletins}>
        <FaCopy /> Copier les Bulletins
      </button>
    </div>
  </div>

        {/* Messages */}
        {message && <div className={`message-banner ${messageType}`}>{message}</div>}
        {/* Loader */}
        {loading && <div className="loader">Chargement...</div>}
      </div>
    </div>
  );
};

export default Exports;