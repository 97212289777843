// colors.ts

export const Colors = {
    // Couleurs principales
    primary: '#023453',      // Bleu foncé
    secondary: '#EFF5FF',    // bleu pale
    accent: '#F5FB8D',       // Jaune pale
    dark: '#011928', // dark
  
    // Couleurs supplémentaires
    transparent: 'transparent',
    backgroundAtGlance: 'rgba(10, 59, 94, 0.7)',
    borderAtGlance: 'rgba(255, 255, 255, 0.4)',
    shadowBlue: '#485DB9',
    shadowBlueDark: '#0F154D',
    orangeAlerte: '#E57A16',
    redAlerte: '#BF0E0E',
    closeButtonBackground: '#F5FB8D',
    backgroundBlock: 'rgba(239, 245, 255, 0.25)',
    backgroundOpacity: 'rgba(2, 52, 83, 0.95)',
    backgroundSelectedDailyForecast: 'rgba(10, 59, 94, 1)',
    weatherIconPlaceholder: 'rgba(200, 200, 200, 0.5)',
  
    // Couleurs pour les indicateurs de vent
    wind50: 'rgba(206, 169, 21, 1)',
    wind70: 'rgba(229, 122, 22, 1)',
    wind90: 'rgba(217, 86, 16, 1)',
    wind105: 'rgba(191, 14, 14, 1)',
  
    // Couleurs additionnelles
    fullDateBackground: 'rgba(211, 228, 253, 0.12)',
    temperatureBackground: 'rgba(253, 255, 242, 0.12)',
  };
  
  // Injecter les couleurs comme variables CSS
  export const injectColorsToCSS = () => {
    const root = document.documentElement;
  
    Object.entries(Colors).forEach(([key, value]) => {
      root.style.setProperty(`--${key}`, value);
    });
  };
  
  // Appeler cette fonction au moment où l'application démarre
  injectColorsToCSS();