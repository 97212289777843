import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { auth } from './firebase'; // Assurez-vous que l'auth provient bien de firebase.js

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();  // Utiliser useNavigate pour la redirection

  useEffect(() => {
    // Configuration de la persistance locale
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        console.log("Persistance locale activée");
      })
      .catch((error) => {
        console.error("Erreur lors de la configuration de la persistance :", error);
      });
  }, []);

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // Connexion avec email et mot de passe
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log("Utilisateur connecté :", userCredential.user);
      navigate('/admin');  // Redirige vers la page admin après la connexion
    } catch (error: any) {
      console.error("Erreur de connexion :", error);
      setError('Erreur de connexion. Veuillez vérifier vos identifiants');
    }
  };

  return (
    <div>
      <h1>Connexion</h1>
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Se connecter</button>
      </form>
      {error && <p>{error}</p>}
    </div>
  );
};

export default Login;