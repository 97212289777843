import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import Annecy from './PrevisionsAnnecy';
import MontagneDefault1700 from './PrevisionsMontagneDefault1700';
import BulletinNeige from './BulletinNeige';
import Bandeaux from './Bandeaux';
import Exports from './Exports';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { onAuthStateChanged } from 'firebase/auth';
import AdminHome from './adminhome';  // Import de AdminHome
import './styles/colors';

const App: React.FC = () => {
  const [user, loading] = useAuthState(auth);
  const hiddenTimeRef = useRef<number | null>(null); // Spécification du type pour TypeScript

  // Gestion de l'état d'authentification
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Utilisateur toujours connecté :", user);
      } else {
        console.log("Aucun utilisateur connecté.");
      }
    });

    return () => unsubscribe(); 
  }, []);

  // Recharge lorsque l'onglet devient actif après plus de 10 minutes d'inactivité
  useEffect(() => {
    const TEN_MINUTES = 1200000; // 20 minutes

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // Enregistrer le timestamp lorsque l'onglet devient inactif
        hiddenTimeRef.current = Date.now();
      } else if (document.visibilityState === 'visible') {
        if (hiddenTimeRef.current) {
          const now = Date.now();
          const elapsed = now - hiddenTimeRef.current;
          if (elapsed >= TEN_MINUTES) {
            window.location.reload();
          }
          hiddenTimeRef.current = null; // Réinitialiser après vérification
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  if (loading) {
    return <div>Chargement...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={user ? <Navigate to="/admin" /> : <Login />} />
        <Route path="/admin" element={user ? <AdminHome /> : <Navigate to="/" />} />
        <Route path="/previsionsAnnecy" element={<Annecy />} />
        <Route path="/previsionsMontagneDefault1700" element={<MontagneDefault1700 />} />
        <Route path="/bulletinNeige" element={<BulletinNeige />} />
        <Route path="/bandeaux" element={<Bandeaux />} /> {/* Route correcte pour Bandeaux */}
        <Route path="/exports" element={<Exports />} />
      </Routes>
    </Router>
  );
};

export default App;